import {
    json,
    defer, 

  } from 'react-router-dom';
import { BASE_URL } from 'data/config';



export async function loadListingsList(status = 'active', token, filters={page: 1, query: ""}) {
  const { page, query } = filters;
  let url = `${BASE_URL}/crm/admin-api/listings?status=${status}&page=${page}&query=${query}`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    let message = 'Failed to load listings';
    try {
      const resData = await response.json();
      message = resData.message;
    } catch (error) {
      console.error('Failed to parse error message:', error);
    }
    throw new Error(message);
  }
  const resData = await response.json();
  return resData.response;
}

export async function loadListingDetail(id, token) {
    const response = await fetch(BASE_URL + "/crm/admin-api/listings?id=" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  
    if (!response.ok) {
      throw json(
        { message: "Could not fetch details for selected listing." },
        {
          status: 500,
        }
      );
    } else {
      const resData = await response.json();
      return resData.response;
    }
  }

// Loaders
// export function listingsLoader(is_archived = false, token) {
//   return defer({
//     listings: loadListingsList(is_archived, token),
//   });
// }


// export async function listingDetailLoader({ request, params, token }) {
//   const id = params.id;

//   return defer({
//     listing: await loadListingDetail(id, token),
//   });
// }