import React from 'react';
import { AreaChart, Area, BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { PieChart, Pie, Cell } from 'recharts';

const revenueData = [
  { name: 'Jan', value: 4000 },
  { name: 'Feb', value: 3000 },
  { name: 'Mar', value: 5000 },
  { name: 'Apr', value: 4000 },
  { name: 'May', value: 6000 },
  { name: 'Jun', value: 5500 },
];

const pieData = [
  { name: 'Product A', value: 400 },
  { name: 'Product B', value: 300 },
  { name: 'Product C', value: 300 },
  { name: 'Product D', value: 200 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const StatCard = ({ title, value, change, isPositive }) => (
  <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md flex-1 min-w-[200px]">
    <h3 className="text-gray-600 dark:text-gray-400 text-sm">{title}</h3>
    <p className="text-gray-900 dark:text-white text-xl lg:text-2xl font-bold">{value}</p>
    <p className={`text-sm ${isPositive ? 'text-green-500' : 'text-red-500'}`}>
      {isPositive ? '▲' : '▼'} {change}
    </p>
  </div>
);

const RevenueChart = () => (
  <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md h-64">
    <h3 className="text-gray-800 dark:text-white mb-4">Revenue</h3>
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={revenueData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
      </AreaChart>
    </ResponsiveContainer>
  </div>
);

const TopSellingProducts = () => (
  <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
    <h3 className="text-gray-800 dark:text-white mb-4">Top Selling Products</h3>
    <div className="overflow-x-auto">
      <table className="w-full text-sm text-left text-gray-600 dark:text-gray-400">
        <thead>
          <tr className="bg-gray-100 dark:bg-gray-700">
            <th className="py-2 px-4">Product Name</th>
            <th className="py-2 px-4">Date</th>
            <th className="py-2 px-4">Price</th>
            <th className="py-2 px-4">Quantity</th>
            <th className="py-2 px-4">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-b dark:border-gray-700">
            <td className="py-2 px-4">Pocket Drone 2.4G</td>
            <td className="py-2 px-4">07 April 2018</td>
            <td className="py-2 px-4">$129.99</td>
            <td className="py-2 px-4">32</td>
            <td className="py-2 px-4">$4089.58</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

const TotalSalesChart = () => (
  <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md h-64">
    <h3 className="text-gray-800 dark:text-white mb-4">Total Sales by Product</h3>
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={pieData}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
          label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
        >
          {pieData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  </div>
);

const OrdersChart = () => (
  <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md h-64">
    <h3 className="text-gray-800 dark:text-white mb-4">Orders</h3>
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={revenueData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="value" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  </div>
);

const GrowthChart = () => (
  <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md h-64">
    <h3 className="text-gray-800 dark:text-white mb-4">Growth</h3>
    <ResponsiveContainer width="100%" height="100%">
      <LineChart data={revenueData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey="value" stroke="#ff7300" />
      </LineChart>
    </ResponsiveContainer>
  </div>
);

const Dashboard = () => {
  return (
    <div className="bg-gray-100 dark:bg-gray-900 min-h-screen p-4 lg:p-8">
      <div className="flex flex-wrap gap-4 mb-8">
        <StatCard title="Customers" value="45,320" change="5.25%" isPositive={true} />
        <StatCard title="Orders" value="45,320" change="1.25%" isPositive={false} />
        <StatCard title="Earnings" value="$7,524" change="7.36%" isPositive={false} />
        <StatCard title="Growth" value="+ 35.52%" change="3.27%" isPositive={true} />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-8">
        <RevenueChart />
        <OrdersChart />
        <GrowthChart />
        <TotalSalesChart />
      </div>
      <TopSellingProducts />
    </div>
  );
};

export default Dashboard;