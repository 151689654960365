import React, { useState } from 'react';
import { Phone, SquarePen } from 'lucide-react';
import { NotificationsList, ClientsList, DocsList, Tabs, Tab, NotificationTemplateForm  } from "components";
import { useRightModal } from 'contexts/RightModalContext';
import { useStateContext } from 'contexts/ContextProvider';

const NotificationTemplateItem = ({ item }) => {
  const { openRightModal } = useRightModal();
  const { currentColor } = useStateContext();

  const handleEditNotificationTemplate = () => {
      openRightModal({
        component: <NotificationTemplateForm item={item}/>,
        title: "Изменить шаблон уведомления",
      });
    };


  return (
    <div className="max-w-4xl mx-auto p-4">
      {/* Header Section */}
      <div className="flex justify-between items-start mb-6">
        <div>
          <h1 className="text-xl font-semibold mb-1">{item.title}</h1> 
          <p className="text-gray-500 text-xs">Создан {item.created}</p> 
        </div>
        <div className="text-right">
          <button className=" text-white px-4 py-2 rounded-md hover:" style={{ backgroundColor: currentColor }} onClick={() => handleEditNotificationTemplate()}>
            <SquarePen size={16} className="mr-1" /> 
          </button>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-sm border mb-6">
        <div className="p-4 border-b">
          <h2 className="font-medium text-sm">СТАТИСТИКА</h2> 
        </div>
        <div className="p-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-4">
            <div className="flex justify-between border-b border-dotted pb-2">
                <span className="text-gray-600 text-sm"> Общее кол-во</span>
                <span className="text-sm">{item.stats?.total}</span> 
              </div>
              <div className="flex justify-between border-b border-dotted pb-2">
                <span className="text-gray-600 text-sm"> Кол-во отправленных</span>
                <span className="text-sm">{item.stats?.sent}</span> 
              </div>
              <div className="flex justify-between border-b border-dotted pb-2">
                <span className="text-gray-600 text-sm">Кол-во прочитанных</span>
                <span className="text-sm">{item.stats?.read}</span> 
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Recent Operations Section */}
      <div className="bg-white rounded-lg shadow-sm border">
        <Tabs initialTab="docs">
          <Tab key="docs" label="Уведомления">
            <NotificationsList template={item}/>
          </Tab>
          <Tab key="money" label="Клиенты">
            <ClientsList targetUrl={item.meta.href + '/clients'} />
          </Tab>
          <Tab key="bonus" label="Документы">
            <div className="text-center text-gray-500 text-sm py-4">
              Нет данных об истории бонусов
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default NotificationTemplateItem;