import React, { useEffect, useState } from 'react';
import { MdOutlineCancel } from "react-icons/md";
import { useRightModal } from 'contexts/RightModalContext';

const RightModal = ({ children, afterClose, title }) => {
    const { closeRightModal } = useRightModal();
    const [isOpen, setIsOpen] = useState(false);
    
    useEffect(() => {
        setIsOpen(true);
    }, []);

    const closeModal = () => {
        setIsOpen(false);
        setTimeout(() => {
            closeRightModal();
            if (afterClose) afterClose();
        }, 300); // Match this with the transition duration
    };

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.key === 'Escape') {
                closeModal();
            }
        };

        document.addEventListener('keydown', handleEscape);
        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, []);

    return (
        <div 
            className="fixed inset-0 z-[9999]  bg-black/20 flex justify-end" 
            onClick={closeModal}
        >
            <div 
                className={`w-800 bg-white dark:bg-[#484B52] overflow-y-auto transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
                onClick={(e) => e.stopPropagation()}
            >
                <div className="flex justify-between items-center p-4 border-b border-gray-200">
                    <h3 className="text-lg font-semibold">{title}</h3>
                    <button
                        onClick={closeModal}
                        className="rounded-full p-2 text-xl text-gray-400 hover:text-gray-600"
                        aria-label="close"
                    >
                        <MdOutlineCancel />
                    </button>
                </div>
                <div className="p-4 m-2 md:m-4 mt-24 md:p-4 bg-white rounded-3xl">
                    <div className="mx-auto max-w-screen-xl bg-white">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RightModal;