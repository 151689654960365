export const hasAccessToRoute = (pathname, userSections) => {
    if (!userSections) return false;
    
    console.log('pathname', pathname, userSections);
    if (pathname === '/') {
      return true;
    }
    const checkAccess = (sections) => {
      return sections.some(section => {
        // Match the first part of the pathname with the section's URL
        if (pathname.startsWith(`/${section.url}`)) {
            console.log(`/${section.url}`, section.included)  

          if (section.included) {
            return true;
          }
          return false;
        }
  
        // Check sub-sections recursively
        if (section.sub_sections) {
          return checkAccess(section.sub_sections);
        }
  
        return false;
      });
    };
  
    return checkAccess(userSections);
  };
  