import React from 'react';
 
import { SectionItem } from 'components'; // Assuming you have a SectionItem component similar to EmployeeItem

const ViewSection = () => {
  const data = useRouteLoaderData('section-detail'); // Adjust the loader ID to match your routing setup
  return (
    <SectionItem section={data.section} />
  );
}

export default ViewSection;
