import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';

const TestRootLayout = () => {
  const navItems = [
    { to: "/dev/test/1", label: "Test 1" },
    { to: "/dev/test/2", label: "Test 2" },
    { to: "/dev/test/3", label: "Test 3" },
    { to: "/dev/test/4", label: "Test 4" },
    { to: "/dev/test/5", label: "Test 5" },
    { to: "/dev/test/6", label: "Test 6" },
    { to: "/dev/test/7", label: "Test 7" },
    { to: "/dev/test/8", label: "Test 8" },
    { to: "/dev/test/9", label: "Test 9" },
    { to: "/dev/test/10", label: "Test 10" },
    { to: "/dev/test/11", label: "Test 11" },
    { to: "/dev/test/12", label: "Test 12" },
  ];

  return (
    <>
      <nav className="flex justify-center bg-gray-200 shadow-md py-4">
        {navItems.map((item, index) => (
          <NavLink 
            key={index}
            to={item.to} 
            className={({ isActive }) =>
              isActive 
                ? 'mx-2 px-4 py-2 text-balapanBlue font-bold border-b-2 border-balapanBlue' 
                : 'mx-2 px-4 py-2 text-gray-600 hover:text-balapanBlue'
            }
            end
          >
            {item.label}
          </NavLink>
        ))}
      </nav>
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <Outlet/>
      </div>
    </>
  );
};

export default TestRootLayout;