import React from 'react';
import { List, NotificationTemplateItem } from 'components';

const formatDate = (date) => new Date(date).toLocaleDateString();

const NotificationTemplatesList = (props) => {
  const filterOptions = [
    {
      key: 'title',
      label: 'Title',
      field: ({ value, onChange, className }) => (
        <input
          type="text"
          placeholder="Введите название"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'is_active',
      label: 'Active',
      field: ({ value, onChange, className }) => (
        <select
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        >
          <option value="">All</option>
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </select>
      ),
      initialValue: '',
    },
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true },
    { key: 'title', label: 'Title' },
    { key: 'content', label: 'Content', component: (item) => <>{item.content}</> },
    { key: 'notifications__count', label: 'Count', component: (item) => <>{item.notifications && item.notifications.meta.size}</> },
    { key: 'is_active', label: 'Active', component: (item) => <>{item.is_active ? 'Yes' : 'No'}</> },
    { key: 'is_sms', label: 'SMS', component: (item) => <>{item.is_sms ? 'Yes' : 'No'}</> },
    {
      key: 'date',
      label: 'Date',
      component: (item) => <>{formatDate(item.date)}</>,
    },
    {
      key: 'created_at',
      label: 'Created At',
      component: (item) => <>{formatDate(item.created_at)}</>,
    },
    {
      key: 'updated_at',
      label: 'Updated At',
      component: (item) => <>{formatDate(item.updated_at)}</>,
    },
  ];

  return (
    <List
      targetUrl="/crm/admin-api/notification-templates"
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={NotificationTemplateItem}
    />
  );
};

export default NotificationTemplatesList;
