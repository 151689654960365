import { useState, useEffect } from 'react';
import { Suspense } from 'react';
import { RightModal, BonusesList, BonusItem } from "components";
import { loadBonusesList } from './Services';
import {AddBonus} from './AddBonus';
import { useStateContext } from 'contexts/ContextProvider';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';

function Bonuses() {
  let title = "Бонусы ";
  const [selectedBonus, setSelectedBonus] = useState(null);
  const [bonuses, setBonuses] = useState([]);
  const [loading, setLoading] = useState(true);
  const { rightModal, setRightModal, showToast } = useStateContext();

  async function fetchBonuses() {
    try {
      const bonusesData = await loadBonusesList();
      setBonuses(bonusesData);
    } catch (error) {
      showToast({
        title: 'Ошибка!',
        content: error.message,
        cssClass: 'e-toast-danger',
        icon: 'e-error toast-icons'
      });
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchBonuses();
  }, []);


  return (
    <>
      <AddBonus fetchBonuses={fetchBonuses}/>
      {loading ? (
        <p className="flex flex-wrap">Загрузка...</p>
      ) : (
        <BonusesList bonuses={bonuses} title={title} selectHandler={(bonus) => {
          setSelectedBonus(bonus);
          setRightModal(true);
        }} />
      )}
      {rightModal && selectedBonus && (
        <RightModal title={"Бонус #" + selectedBonus.id} afterClose={() => setSelectedBonus(null)}>
          <Suspense fallback={<p style={{ textAlign: 'center' }}>Загрузка...</p>}>
            <BonusItem bonus={selectedBonus} />
          </Suspense>
          <h1 className="flex md:flex-row flex-col">Here will be related statistics and more info...</h1>
        </RightModal>
      )}
    </>
  );
}

export default Bonuses;
