import {React, useState} from 'react';
import { Check, MessageCircle, ChevronLeft, ChevronRight, ChevronDown } from 'lucide-react';

const SalesTable = ({ sales }) => {
  return (
    <div className="bg-white rounded-lg shadow overflow-x-auto">
      <table className="w-full">
        <thead>
          <tr className="bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            <th className="p-3">Статус</th>
            <th className="p-3">Документ</th>
            <th className="p-3">Время</th>
            <th className="p-3">Позиций</th>
            <th className="p-3">Сумма</th>
            <th className="p-3">Оплаченные</th>
            <th className="p-3">Отправитель</th>
            <th className="p-3">Получатель</th>
            <th className="p-3">Автор</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {sales.map((sale, index) => (
            <tr key={index} className="hover:bg-gray-50">
              <td className="p-3">
                <div className="flex items-center">
                  <input type="checkbox" className="h-4 w-4 text-blue-600 rounded" checked={true} readOnly />
                  <div className="ml-2 w-1 h-6 bg-green-500 rounded-full"></div>
                </div>
              </td>
              <td className="p-3">
                <a href="#" className="text-blue-600 hover:underline flex items-center">
                  {sale.document}
                  <MessageCircle size={16} className="ml-2 text-gray-400" />
                </a>
              </td>
              <td className="p-3">{sale.time}</td>
              <td className="p-3">{sale.positions}</td>
              <td className="p-3">{sale.amount.toFixed(2)}</td>
              <td className="p-3">
                {sale.paid ? (
                  <span className="flex items-center text-green-600">
                    <Check size={16} className="mr-1" />
                    {sale.amount.toFixed(2)}
                  </span>
                ) : (
                  sale.amount.toFixed(2)
                )}
              </td>
              <td className="p-3">{sale.sender}</td>
              <td className="p-3">{sale.recipient}</td>
              <td className="p-3">{sale.author}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const PeriodSelector = () => {
  const [selectedPeriod, setSelectedPeriod] = useState('23 авг — 29 авг');
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState('АВГУСТ 2024');

  const periods = ['сегодня', 'Вчера', '7 дней', '30 дней', 'Этот месяц', 'Прошлый месяц', 'квартал'];

  const toggleCalendar = () => setIsCalendarOpen(!isCalendarOpen);

  const changeMonth = (direction) => {
    // This is a placeholder. In a real app, you'd implement actual month changing logic
    setCurrentMonth(direction === 'next' ? 'СЕНТЯБРЬ 2024' : 'ИЮЛЬ 2024');
  };

  return (
    <div className="bg-white rounded-lg shadow p-4">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
          <span className="text-gray-600">дата</span>
          <div className="relative">
            <button
              onClick={toggleCalendar}
              className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm flex items-center"
            >
              {selectedPeriod}
              <ChevronDown size={16} className="ml-1" />
            </button>
            {isCalendarOpen && (
              <div className="absolute top-full left-0 mt-2 bg-white rounded-lg shadow-lg p-4 z-10">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    {periods.map((period) => (
                      <button
                        key={period}
                        className="block w-full text-left py-1 px-2 hover:bg-gray-100 rounded"
                        onClick={() => setSelectedPeriod(period)}
                      >
                        {period}
                      </button>
                    ))}
                  </div>
                  <div>
                    <div className="flex justify-between items-center mb-2">
                      <button onClick={() => changeMonth('prev')}><ChevronLeft size={16} /></button>
                      <span className="font-bold">{currentMonth}</span>
                      <button onClick={() => changeMonth('next')}><ChevronRight size={16} /></button>
                    </div>
                    <div className="grid grid-cols-7 gap-1">
                      {/* This is a placeholder for the calendar. You'd need to implement the actual calendar logic */}
                      {[...Array(31)].map((_, i) => (
                        <button key={i} className={`w-8 h-8 rounded-full ${i === 22 ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'}`}>
                          {i + 1}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="mt-4 text-right">
                  <span className="text-sm text-gray-600">2024-08-23 to 2024-08-29 (7Дней)</span>
                  <button className="ml-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
                    Применить
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <button className="px-4 py-2 bg-gray-100 text-gray-800 rounded hover:bg-gray-200 flex items-center">
          <svg className="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 6H20M4 12H20M4 18H11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          Фильтр
        </button>
      </div>
    </div>
  );
};



const SalesDashboard = () => {
  // This would typically come from an API or state management
  const salesData = [
    { document: 'Продажа #384606', time: '15:08', positions: 1, amount: 399.00, paid: true, sender: 'Балапан ЖА', recipient: 'Розничный покупатель', author: 'Балапан ЖА' },
    { document: 'Продажа #384610', time: '15:06', positions: 4, amount: 2755.06, paid: true, sender: 'OFF PRICE', recipient: 'Розничный покупатель', author: 'OFF PRICE' },
    // ... add more sales data
  ];

  return (
    <div className="container mx-auto p-4">
      <PeriodSelector />
      <div className="mt-4">
        <SalesTable sales={salesData} />
      </div>
    </div>
  );
};

export default SalesDashboard;