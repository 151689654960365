import React, { useState, useEffect, useRef } from 'react';

const Tooltip = ({ children, content, trigger = 'hover', className }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  // Handle outside click to hide tooltip
  useEffect(() => {
    if (trigger === 'click') {
      const handleClickOutside = (event) => {
        if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
          setShowTooltip(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [trigger]);

  const handleTriggerClick = () => {
    setShowTooltip((prev) => !prev); // Toggle tooltip visibility on click
  };

  return (
    <div
      className={className + ' relative'}
      ref={tooltipRef}
      {...(trigger === 'click'
        ? { onClick: handleTriggerClick }
        : {
            onMouseEnter: () => setShowTooltip(true),
            onMouseLeave: () => setShowTooltip(false),
          })}
    >
      {children}
      {showTooltip && (
        <div
          className="bg-gray-800 text-white px-4 py-2 rounded-md absolute z-10 w-80 p-4 mt-2 left-1/2 -translate-x-1/2"
          onClick={(e) => e.stopPropagation()} // Prevent clicks inside the tooltip from hiding it
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
