import { json, defer } from 'react-router-dom';
import { BASE_URL} from 'data/config';

export async function loadDocsList(queryString="", token="token") {
    let url = BASE_URL + "/docs/admin-api/docs" + queryString;
    

    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    });
    if (!response.ok) {
        throw json(
            { message: "Failed to load docs" },
            {
                status: response.status,
                statusText: response.statusText,
            }
        );
    } else {
        const resData = await response.json();
        return resData.response;
    }
}


export async function loadPositionsList(queryString, token="token") {
    let url = BASE_URL + "/docs/admin-api/positions" + queryString;
    

    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    });
    if (!response.ok) {
        throw json(
            { message: "Failed to load docs" },
            {
                status: response.status,
                statusText: response.statusText,
            }
        );
    } else {
        const resData = await response.json();
        return resData.response;
    }
}
