import React, { useState } from 'react';
import { EmployeeGroupSelector, StoreSelector } from 'components';
import { X, Eye, EyeOff } from 'lucide-react';
import { useRightModal } from 'contexts/RightModalContext';
import { useStateContext } from 'contexts/ContextProvider';
import { crudEmployee } from 'services';
import { fr } from 'date-fns/locale';


const EmployeeForm = ({ employee }) => {
  const { closeRightModal } = useRightModal();
  const { showToast } = useStateContext();
  const [showPassword, setShowPassword] = useState(false);
  const [employeeData, setEmployeeData] = useState(employee || {});
  const [stores, setStores] = useState(employee ? employee?.stores : []);
  const [groups, setGroups] = useState(employee ? [employee?.group] : []);

  const handleSave = async () => {
    try {
      const data = {
        ...employeeData,
        store_ids: stores.map((store) => store.id),
        group_id: groups[0]?.id,
      };
      showToast({
        title: 'Success!',
        content: 'Employee has been saved successfully!',
        cssClass: 'e-toast-success',
        icon: 'e-success toast-icons',
      });
    } catch (error) {
      console.error(error);
    }
    closeRightModal();
  };

  const handleInputChange = (field, value) => {
    setEmployeeData((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <>
      <h2 className="text-2xl font-semibold mb-6">Создание сотрудника</h2>
      <div>
        <div className="mb-4">
          <label htmlFor="first_name" className="block text-sm font-medium text-gray-700 mb-1">
            Имя <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="first_name"
            placeholder="Имя"
            className="w-full px-3 py-2 border rounded-md"
            value={employeeData.first_name || ''}
            onChange={(e) => handleInputChange('first_name', e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="last_name" className="block text-sm font-medium text-gray-700 mb-1">
            Фамилия <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="last_name"
            placeholder="Фамилия"
            className="w-full px-3 py-2 border rounded-md"
            value={employeeData.last_name || ''}
            onChange={(e) => handleInputChange('last_name', e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Отделы <span className="text-gray-400 text-xs">?</span>
          </label>
          <EmployeeGroupSelector
            selectedEmployeeGroups={groups}
            onSelect={(group) => setGroups((prev) => [...prev, group])}
            onRemove={(id) => setGroups((prev) => prev.filter((e) => e.id !== id))}
            className="flex flex-wrap gap-2 mb-2"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Магазины <span className="text-gray-400 text-xs">?</span>
          </label>
          <StoreSelector
            selectedStores={stores}
            onSelect={(store) => setStores((prev) => [...prev, store])}
            onRemove={(id) => setStores((prev) => prev.filter((e) => e.id !== id))}
            className="flex flex-wrap gap-2 mb-2"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
            Email
          </label>
          <input
            type="email"
            id="email"
            placeholder="email@gmail.com"
            className="w-full px-3 py-2 border rounded-md"
            value={employeeData.email || ''}
            onChange={(e) => handleInputChange('email', e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
            Телефон сотрудника <span className="text-red-500">*</span>
          </label>
          <input
            type="tel"
            id="phone"
            placeholder="0778077801"
            className="w-full px-3 py-2 border rounded-md"
            value={employeeData.phone || ''}
            onChange={(e) => handleInputChange('phone', e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
            Пароль сотрудника <span className="text-red-500">*</span>
          </label>
          <div className="relative">
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              className="w-full px-3 py-2 border rounded-md pr-10"
              value={employeeData.password || ''}
              onChange={(e) => handleInputChange('password', e.target.value)}
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600"
            >
              {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          </div>
        </div>

        <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600" onClick={handleSave}>
          Сохранить
        </button>
      </div>
    </>
  );
};

export default EmployeeForm;
