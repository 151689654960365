import React from 'react';
import { MoreVertical, Phone, Mail, Building } from 'lucide-react';

const EmployeeCard = ({ name, role, phone, email, branch }) => (
  <div className="bg-white rounded-lg shadow p-4">
    <div className="flex justify-between items-start mb-4">
      <div className="flex items-center">
        <div className="w-12 h-12 bg-gray-200 rounded-full mr-3 flex items-center justify-center">
          <img src="/api/placeholder/48/48" alt={name} className="w-full h-full object-cover rounded-full" />
        </div>
        <div>
          <h3 className="font-semibold">{name}</h3>
          <p className="text-sm text-gray-600">{role}</p>
        </div>
      </div>
      <button className="text-gray-400 hover:text-gray-600">
        <MoreVertical size={20} />
      </button>
    </div>
    <div className="space-y-2">
      {phone && (
        <div className="flex items-center text-sm">
          <Phone size={16} className="mr-2 text-gray-400" />
          <span>{phone}</span>
        </div>
      )}
      {email && (
        <div className="flex items-center text-sm">
          <Mail size={16} className="mr-2 text-gray-400" />
          <span>{email}</span>
        </div>
      )}
      {branch && (
        <div className="flex items-center text-sm">
          <Building size={16} className="mr-2 text-gray-400" />
          <span>{branch}</span>
        </div>
      )}
    </div>
  </div>
);

const EmployeeCards = () => {
  const employeeSummary = {
    total: 15,
    owners: 1,
    managers: 2,
    cashiers: 10,
    warehousemen: 2
  };

  const employees = [
    { name: 'dina', role: 'Владелец', email: 'dina@usa.kg' },
    { name: 'Saule Kaldanova', role: 'Управляющий', phone: '+996775019693', email: 'saule@usa.kg' },
    { name: 'Талант Токсонов', role: 'Управляющий', phone: '0096 (775) 010 9693', email: 'toksonov@usa.kg' },
    { name: 'Балапашка №2', role: 'Кассир', phone: '0770291193', email: 'talant.toksonov@mail.ru', branch: 'Балапашка №2' },
    { name: 'Балапан ЖА', role: 'Кассир', phone: '+996775019693', email: 'talant@usa.kg', branch: 'Балапан ЖА' },
  ];

  return (
    <div className="p-4 bg-gray-100">
      <div className="bg-white rounded-lg shadow p-4 mb-4">
        <h2 className="font-semibold mb-2">Всего {employeeSummary.total} сотрудников</h2>
        <ul className="list-disc pl-5">
          <li>Владельцев: {employeeSummary.owners}</li>
          <li>Управляющих: {employeeSummary.managers}</li>
          <li>Кассиры: {employeeSummary.cashiers}</li>
          <li>Кладовщиков: {employeeSummary.warehousemen}</li>
        </ul>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="bg-white rounded-lg shadow p-4 flex items-center justify-center">
          <button className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50">
            Создать
          </button>
        </div>
        {employees.map((employee, index) => (
          <EmployeeCard key={index} {...employee} />
        ))}
      </div>
    </div>
  );
};

export default EmployeeCards;