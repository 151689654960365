import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Search, Check, ChevronDown, X } from 'lucide-react';
import { loadEmployeesList } from 'services';

const EmployeeSelector = ({ 
  onSelect, 
  onRemove,
  placeholder = "Select employees...",
  selectedEmployees = [],
  consultantsOnly = false,
}) => {
  const [open, setOpen] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const dropdownRef = useRef(null);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      loadEmployees();
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const loadEmployees = useCallback(async (searchString = "") => {
    setLoading(true);
    try {
      const queryParams = [
        consultantsOnly && `filter=group=[5]`,
        searchString && `search=${searchString}`,
      ].filter(Boolean);
      
      const queryString = queryParams.length > 0 
        ? `?${queryParams.join('&')}` 
        : '';
      
      const response = await loadEmployeesList(queryString);
      setEmployees(response.rows);
    } catch (error) {
      console.error('Failed to load employees:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSearch = useCallback((value) => {
    setSearchValue(value);
    const debounceTimeout = setTimeout(() => {
      loadEmployees(value);
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [loadEmployees]);


  const getButtonText = () => {
    if (selectedEmployees.length === 0) return placeholder;
    if (selectedEmployees.length === 1) return selectedEmployees[0].name;
    return `Chosen ${selectedEmployees.length}`;
  };

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className="w-full px-4 py-2 text-left bg-white border rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
      >
        <div className="flex items-center justify-between">
          <span className={`block truncate ${selectedEmployees.length === 0 && 'text-gray-500'}`}>
            {getButtonText()}
          </span>
          <ChevronDown className="w-4 h-4 ml-2 text-gray-400" />
        </div>
      </button>

      {selectedEmployees.length > 0 && (
        <div className="flex flex-wrap gap-2 mt-2">
          {selectedEmployees.map((employee) => (
            <div
              key={employee.id}
              className="flex items-center gap-1 px-2 py-1 text-sm bg-blue-100 text-blue-800 rounded-full"
            >
              <span>{employee.name}</span>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove?.(employee.id);
                }}
                className="p-1 hover:bg-blue-200 rounded-full"
              >
                <X className="w-3 h-3" />
              </button>
            </div>
          ))}
        </div>
      )}

      {open && (
        <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg">
          <div className="flex items-center px-3 py-2 border-b">
            <Search className="w-4 h-4 text-gray-400" />
            <input
              type="text"
              className="w-full px-2 py-1 ml-2 text-sm focus:outline-none"
              placeholder="Search employees..."
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>

          <div className="max-h-60 overflow-auto">
            {loading ? (
              <div className="px-4 py-2 text-sm text-gray-500">Loading...</div>
            ) : employees.length === 0 ? (
              <div className="px-4 py-2 text-sm text-gray-500">No employees found</div>
            ) : (
              employees.map((employee) => (
                <button
                  key={employee.id}
                  className="w-full px-4 py-2 text-sm text-left hover:bg-gray-100 focus:outline-none focus:bg-gray-100 flex items-center justify-between"
                  onClick={() => onSelect?.(employee)}
                >
                  <span>{employee.name}</span>
                  {selectedEmployees.some(e => e.id === employee.id) && (
                    <Check className="w-4 h-4 text-blue-500" />
                  )}
                </button>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EmployeeSelector;