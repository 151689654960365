import React from 'react';
import { Calendar, Tag, FileText } from 'lucide-react';

const ProductItem = ({ item }) => {
  return (
    <>
      {/* Product Info */}
      <div className="flex mb-6">
        <div className="w-1/4 bg-gray-200 rounded-lg flex items-center justify-center">
          <img src="/api/placeholder/150/150" alt="Product" className="w-32 h-32 object-cover" />
        </div>
        <div className="w-3/4 pl-6">
          <h2 className="text-xl font-semibold mb-2">товар</h2>
          <h1 className="text-2xl font-bold mb-4">{item.name}</h1>
          <div className="grid grid-cols-2 gap-2">
            <div>
              <p className="text-gray-600">Штрих-код:</p>
              <p>{item.barcode}</p>
            </div>
            <div>
              <p className="text-gray-600">Артикул:</p>
              <p>—</p>
            </div>
            <div>
              <p className="text-gray-600">Код товара:</p>
              <p>{item.code}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Additional Info */}
      <div className="grid grid-cols-2 gap-4 mb-6">
        <div className="flex items-center">
          <Calendar size={16} className="mr-2 text-gray-400" />
          <span className="text-gray-600 mr-2">Создан</span>
          <span>{new Date(item.created).toLocaleDateString()}</span>
        </div>
        <div className="flex items-center">
          <Tag size={16} className="mr-2 text-gray-400" />
          <span className="text-gray-600 mr-2">Категории</span>
          <span>—</span>
        </div>
        <div className="flex items-center">
          <FileText size={16} className="mr-2 text-gray-400" />
          <span className="text-gray-600 mr-2">Описание</span>
          <span>—</span>
        </div>
      </div>

      {/* Prices */}
      <h3 className="text-lg font-semibold mb-2">ЦЕНЫ</h3>
      <table className="w-full mb-6">
        <thead>
          <tr className="bg-gray-100">
            <th className="p-2 text-left">Цена продажи</th>
            <th className="p-2 text-left">Цена закупки</th>
            <th className="p-2 text-left">Себестоимость</th>
            <th className="p-2 text-left">Наценка</th>
            <th className="p-2 text-left">Маржинальность</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="p-2">{item.price} сом</td>
            <td className="p-2">{item.purchase} сом</td>
            <td className="p-2">{item.cost} сом</td>
            <td className="p-2">{((item.price - item.cost) / item.cost * 100).toFixed(2)}%</td>
            <td className="p-2">{((item.price - item.cost) / item.price * 100).toFixed(2)}%</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ProductItem;