import React from 'react';
import { Tabs, Tab, DocsList, ProductsList } from 'components';


const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toLocaleDateString('ru-RU', { 
    day: 'numeric', 
    month: 'long', 
    year: 'numeric' 
  });
};

const formatNumber = (number) => {
  return number?.toLocaleString('ru-RU', { 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2 
  }) || '0.00';
};

const formatPhone = (phone) => {
  if (!phone) return '';
  return phone.startsWith('+') ? phone : `+${phone}`;
};

const SupplierItemItem = ({ item }) => {
    const birthDate = item.birthday === '2100-01-01' ? null : item.birthday;

  return (
    <div className="max-w-3xl mx-auto bg-white p-6">
      {/* Header Section */}
      <div className="mb-6">
        <div className="flex justify-between items-start">
          <h1 className="text-2xl font-normal mb-1">{`${item.name}`}</h1>
          <span className="text-gray-400">Клиент</span>
        </div>
        <div className="text-sm mb-2">
          Создан {formatDate(item.created)}
        </div>
        <div className="flex gap-2">
          <span className="bg-red-500 text-white text-sm px-2 py-1 rounded">
            Кешбэк: {item.cashback_percent}%
          </span>
          <span className="bg-purple-500 text-white text-sm px-2 py-1 rounded">
            Бонусов: {formatNumber(item.bonus_balance)}
          </span>
          <span className="bg-blue-100 text-blue-600 text-sm px-2 py-1 rounded">
            Потрачено бонусов: {formatNumber(item.bonus_spent)}
          </span>
        </div>
      </div>

      {/* Birth Date */}
      {birthDate && (
        <div className="mb-6">
          <div className="flex items-center gap-2">
            <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
            <span>{formatDate(birthDate)}</span>
          </div>
        </div>
      )}

      {/* Contacts Section */}
      <div className="mb-6">
        <h2 className="text-lg font-medium mb-2">КОНТАКТЫ</h2>
        <div className="flex items-center gap-2">
          <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
          </svg>
          <span>{formatPhone(item.phone)}</span>
        </div>
      </div>

      {/* Operations Section */}
      {
        <div>
          <Tabs initialTab='docs'>
            <Tab key='docs' label='Документы'>
                <DocsList supplier={item}/>
            </Tab>
            <Tab key='products' label='Товары'>
              <ProductsList supplier={item} />
            </Tab>
            <Tab key='bonus' label='История бонусов'>
                <div className="text-center text-gray-500 font-medium py-4">Нет данных об истории бонусов</div>
            </Tab>
          </Tabs>
        </div>
    }
    </div>
  );
};

export default SupplierItemItem;