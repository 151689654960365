import { Outlet, NavLink } from 'react-router-dom';
import { useStateContext } from 'contexts/ContextProvider';

function PartiesRootLayout() {
    const { currentMode } = useStateContext();
    
    return (
        <div className="pt-10 bg-white dark:bg-gray-700 rounded-3xl">
            <div className='mx-auto max-w-screen-xl bg-white dark:bg-gray-700'>
                <Outlet />
            </div>
        </div>
    );
}

export default PartiesRootLayout;
