import React from 'react';
import { List, ProductGroupItem } from 'components';

const formatDate = (date) => (date ? new Date(date).toLocaleDateString() : '—');

const ProductGroupsList = (props) => {
  const filterOptions = [
    {
      key: 'name',
      label: 'Name',
      field: ({ value, onChange, className }) => (
        <input
          type="text"
          value={value}
          placeholder="Search by Name"
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'created__gte',
      label: 'Created After',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'created__lte',
      label: 'Created Before',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'updated__gte',
      label: 'Updated After',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'updated__lte',
      label: 'Updated Before',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true },
    { key: 'name', label: 'Name' },
    { key: 'created', label: 'Created At', component: (item) => <>{formatDate(item.created)}</> },
    { key: 'updated', label: 'Updated At', component: (item) => <>{formatDate(item.updated)}</> },
  ];

  return (
    <List
      targetUrl={props.targetUrl || "/products/admin-api/product-groups/"}
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={ProductGroupItem}
    />
  );
};

export default ProductGroupsList;
