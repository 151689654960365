import React from 'react';

const NotificationItem = ({ item }) => {
  const { meta, title, description, tag, client, is_read } = item;

  // Generate the tag color dynamically using the RGB values from the model
  const tagColor = `rgb(${tag.red}, ${tag.green}, ${tag.blue})`;

  return (
    <div className={`p-4 border-b ${is_read ? 'bg-gray-50' : 'bg-white'} hover:bg-gray-100 transition-colors`}>
      {/* Header */}
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center space-x-2">
          {/* Tag */}
          <span
            className="text-xs font-medium px-2 py-1 rounded-full"
            style={{ backgroundColor: tagColor, color: 'white' }}
          >
            {tag.text}
          </span>
          {/* Notification Title */}
          <h3 className="text-lg font-semibold">{title}</h3>
        </div>
        {/* Read/Unread Indicator */}
        {!is_read && (
          <span className="w-2 h-2 bg-blue-500 rounded-full"></span>
        )}
      </div>

      {/* Description */}
      <p className="text-sm text-gray-600 mb-2">{description}</p>

      {/* Client Information */}
      <div className="text-sm text-gray-500">
        <span className="font-medium">Клиент:</span> {client.meta.name}
      </div>

      {/* Meta Information */}
      <div className="text-xs text-gray-400 mt-2">
        <span>ID: {meta.id}</span> · <span>Тип: {meta.type}</span>
      </div>
    </div>
  );
};

export default NotificationItem;