import React from 'react';
import { List, ClientItem } from 'components';

const formatDate = (date) => (date ? new Date(date).toLocaleDateString() : '—');

const ClientsList = (props) => {
  const filterOptions = [
    {
      key: 'last_sale__gte',
      label: 'Last Sale (From)',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'last_sale__lt',
      label: 'Last Sale (To)',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'last_notification__gte',
      label: 'Last Notification (From)',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'last_notification__lt',
      label: 'Last Notification (To)',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'bonus_balance__gte',
      label: 'Bonus Balance (Min)',
      field: ({ value, onChange, className }) => (
        <input
          type="number"
          step="0.01"
          value={value}
          className={className}
          onChange={(e) => onChange(parseFloat(e.target.value))}
        />
      ),
      initialValue: '',
    },
    {
      key: 'bonus_balance__lt',
      label: 'Bonus Balance (Max)',
      field: ({ value, onChange, className }) => (
        <input
          type="number"
          step="0.01"
          value={value}
          className={className}
          onChange={(e) => onChange(parseFloat(e.target.value))}
        />
      ),
      initialValue: '',
    },
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true },
    { key: 'name', label: 'Name' },
    { key: 'phone', label: 'Phone' },
    { key: 'email', label: 'Email' },
    { key: 'bonus_balance', label: 'Bonus Balance', component: (item) => <>{item.bonus_balance.toFixed(2)}</> },
    { 
      key: 'doc__count',
      label: 'Doc Count', 
      component: (item) => <>{item.docs?.meta.size}</> 
    },
    { key: 'last_sale', label: 'Last Sale', component: (item) => <>{formatDate(item.last_sale)}</> },
    { key: 'last_notification', label: 'Last Notification', component: (item) => <>{formatDate(item.last_sms)}</> },
    { key: 'gender', label: 'Gender', component: (item) => <>{item.gender}</> },
    { key: 'created', label: 'Created At', component: (item) => <>{formatDate(item.created)}</> },
    { key: 'updated', label: 'Updated At', component: (item) => <>{formatDate(item.updated)}</> },
  ];

  return (
    <List
      targetUrl={props.targetUrl || "/parties/admin-api/clients/"}
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={ClientItem}
      
    />
  );
};

export default ClientsList;
