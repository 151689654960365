import React, { useState } from 'react';
import { X, Eye, EyeOff } from 'lucide-react';

const EmployeeForm = () => {
  const [role, setRole] = useState('Управляющий');
  const [showPassword, setShowPassword] = useState(false);
  const [stores, setStores] = useState(['Балапан Анар №4', 'Балапан ЖА', 'OFF PRICE']);

  const handleStoreRemove = (store) => {
    setStores(stores.filter(s => s !== store));
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md max-w-2xl mx-auto">
      <div className="flex justify-between items-center mb-6">
        <button className="text-gray-400 hover:text-gray-600">
          <X size={24} />
        </button>
        <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">
          Сохранить
        </button>
      </div>

      <h2 className="text-2xl font-semibold mb-6">Создание сотрудника</h2>

      <form>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Роль <span className="text-red-500">*</span>
          </label>
          <div className="flex space-x-2">
            {['Управляющий', 'Кассир', 'Кладовщик'].map((r) => (
              <button
                key={r}
                type="button"
                onClick={() => setRole(r)}
                className={`px-4 py-2 rounded ${
                  role === r
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                }`}
              >
                {r}
              </button>
            ))}
          </div>
        </div>

        <button type="button" className="text-blue-500 hover:underline mb-4">
          Изменить права доступа пользователя
        </button>

        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
            Имя и фамилия сотрудника <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            id="name"
            placeholder="Name"
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Фотография
          </label>
          <div className="border-2 border-dashed border-gray-300 rounded-md p-6 text-center">
            <p className="text-gray-500">Выберите фото для загрузки</p>
            <p className="text-gray-400 text-sm">или перетащите его мышью</p>
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Магазины <span className="text-gray-400 text-xs">?</span>
          </label>
          <div className="flex flex-wrap gap-2 mb-2">
            {stores.map((store) => (
              <span key={store} className="bg-gray-200 px-2 py-1 rounded flex items-center">
                {store}
                <button onClick={() => handleStoreRemove(store)} className="ml-1 text-gray-500 hover:text-gray-700">
                  <X size={14} />
                </button>
              </span>
            ))}
          </div>
          <select className="w-full px-3 py-2 border rounded-md">
            <option value="">Select store</option>
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
            Email <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            id="email"
            placeholder="email@gmail.com"
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
            Телефон сотрудника
          </label>
          <input
            type="tel"
            id="phone"
            placeholder="0778077801"
            className="w-full px-3 py-2 border rounded-md"
          />
        </div>

        <div className="mb-4">
          <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
            Пароль сотрудника <span className="text-red-500">*</span>
          </label>
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              className="w-full px-3 py-2 border rounded-md pr-10"
            />
            <button
              type="button"
              onClick={() => setShowPassword(!showPassword)}
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600"
            >
              {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EmployeeForm;