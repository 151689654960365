import React from 'react';


export const Tabs = ({ initialTab, children }) => {
    const [activeTab, setActiveTab] = React.useState(initialTab);

    return (
      <div>
        <div className="flex space-x-1 mb-4 bg-gray-100 p-1 rounded-lg">
          {React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) return null;
            const { key, props } = child;
            const isActive = activeTab === key;
            return (
              <button
                key={key}
                onClick={() => setActiveTab(key)}
                className={`flex-1 py-2 px-4 rounded-md text-sm font-medium transition-colors
                  ${isActive ? 'bg-white shadow-sm' : 'hover:bg-gray-50 text-gray-600'}`}
              >
                {props.label}
              </button>
            );
          })}
        </div>
  
        {/* Render the active tab content */}
        {React.Children.map(children, (child) => {
          if (!React.isValidElement(child) || child.key !== activeTab) return null;
          return child;
        })}
      </div>
    );
  };
  
  // Tab component
  export const Tab = ({ children }) => <>{children}</>;