import React from 'react';
import { List, SupplierItem } from 'components';

const formatDate = (date) => (date ? new Date(date).toLocaleDateString() : '—');

const SuppliersList = (props) => {
  const filterOptions = [
    {
      key: 'created__gte',
      label: 'Created At (From)',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'created__lt',
      label: 'Created At (To)',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'updated__gte',
      label: 'Updated At (From)',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'updated__lt',
      label: 'Updated At (To)',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true },
    { key: 'name', label: 'Name' },
    { key: 'phone', label: 'Phone' },
    { key: 'email', label: 'Email', component: (item) => <>{item.email || '—'}</> },
    {
      key: 'product__count',
      label: 'Products',
      component: (item) => (
        <>{item.products?.meta.size}</>
      ),
    },
    { key: 'created', label: 'Created At', component: (item) => <>{formatDate(item.created)}</> },
    { key: 'updated', label: 'Updated At', component: (item) => <>{formatDate(item.updated)}</> },
  ];

  return (
    <List
      targetUrl={props.targetUrl || '/parties/admin-api/suppliers/'}
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={SupplierItem}
    />
  );
};

export default SuppliersList;