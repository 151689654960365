import React from 'react';

const Icons = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="hidden">
    <symbol id="icon-dashboard" viewBox="0 0 24 24">
      <path d="M4 4h6v6H4V4zm10 0h6v6h-6V4zM4 14h6v6H4v-6zm10 0h6v6h-6v-6z" />
    </symbol>
    <symbol id="icon-docs" viewBox="0 0 24 24">
      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zm-1 1v5h5v10H6V4h7z" />
    </symbol>
    <symbol id="icon-company" viewBox="0 0 24 24">
      <path d="M12 7V3H2v18h20V7H12zM6 19H4v-2h2v2zm0-4H4v-2h2v2zm0-4H4V9h2v2zm0-4H4V5h2v2zm4 12H8v-2h2v2zm0-4H8v-2h2v2zm0-4H8V9h2v2zm0-4H8V5h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2V9h8v10z" />
    </symbol>
    <symbol id="icon-parties" viewBox="0 0 24 24">
      <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5s-3 1.34-3 3 1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" />
    </symbol>
    <symbol id="icon-products" viewBox="0 0 24 24">
      <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" />
    </symbol>
    <symbol id="icon-app" viewBox="0 0 24 24">
      <path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z" />
    </symbol>
    <symbol id="icon-crm" viewBox="0 0 24 24">
      <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z" />
    </symbol>
    <symbol id="icon-dev" viewBox="0 0 24 24">
      <path d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z" />
    </symbol>
    <symbol id="icon-pos" viewBox="0 0 24 24">
      <path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z" />
    </symbol>
  </svg>
);

const SidebarItem = ({ icon, text }) => (
  <div className="flex items-center space-x-2 p-2 hover:bg-gray-100 rounded-lg cursor-pointer">
    <svg className="w-6 h-6">
      <use href={`#${icon}`} />
    </svg>
    <span>{text}</span>
  </div>
);

const Sidebar = () => {
  return (
    <div className="w-64 h-screen bg-white border-r">
      <Icons />
      <nav className="p-4 space-y-2">
        <SidebarItem icon="icon-dashboard" text="Панель" />
        <SidebarItem icon="icon-docs" text="Документы" />
        <SidebarItem icon="icon-company" text="КОМПАНИЯ" />
        <SidebarItem icon="icon-parties" text="КОНТРАГЕНТЫ" />
        <SidebarItem icon="icon-products" text="ТОВАРЫ" />
        <SidebarItem icon="icon-app" text="ПРИЛОЖЕНИЯ" />
        <SidebarItem icon="icon-crm" text="CRM" />
        <SidebarItem icon="icon-dev" text="DEV" />
        <SidebarItem icon="icon-pos" text="КАССА" />
      </nav>
    </div>
  );
};

export default Sidebar;