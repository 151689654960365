import React, { createContext, useContext, useState, useCallback } from 'react';

const RightModalContext = createContext({
  modals: [],
  openRightModal: () => {},
  closeRightModal: () => {}
});

export const RightModalProvider = ({ children }) => {
  const [modals, setModals] = useState([]);

  const openRightModal = useCallback((modal) => {
    const newModal = { 
      ...modal, 
      id: `modal-${Date.now()}-${Math.random().toString(36).substr(2, 9)}` 
    };
    setModals(prev => [...prev, newModal]);
  }, []);

  const closeRightModal = useCallback((id) => {
    if (id) {
      setModals(prev => prev.filter(modal => modal.id !== id));
    } else {
      setModals(prev => prev.slice(0, -1));
    }
  }, []);

  return (
    <RightModalContext.Provider value={{ modals, openRightModal, closeRightModal }}>
      {children}
    </RightModalContext.Provider>
  );
};

export const useRightModal = () => {
  const context = useContext(RightModalContext);
  if (!context) {
    throw new Error('useRightModal must be used within a RightModalProvider');
  }
  return context;
};