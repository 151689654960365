import React, { useState } from "react";
import { Outlet, NavLink, useNavigate } from "react-router-dom";
import { useStateContext } from "contexts/ContextProvider";
import { Header, Button } from "components";

function DocsRootLayout() {
    const { currentMode, currentColor } = useStateContext();
    const [hoveredLink, setHoveredLink] = useState(null);
    const navigate = useNavigate();

    const getNavLinkStyle = ({ isActive, name }) => ({
        borderBottomColor: isActive || hoveredLink === name ? currentColor : 'transparent',
        color: isActive || hoveredLink === name ? currentColor : currentMode === 'Dark' ? '#D1D5DB' : '#4B5563',
        transition: 'color 200ms, border-bottom-color 200ms',
    });

    const links = [
        {
            'name': 'Документы',
            'path': '',
        },
        {
            'name': 'Исправления Консультантов',
            'path': 'set-consultants/',
        }
    ];

    return (
        <div className={currentMode === 'Dark' ? 'dark' : ''}>
            <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white dark:bg-gray-700 rounded-3xl">
            <div className="mx-auto max-w-screen-xl bg-white dark:bg-gray-700">
                <div className="flex justify-between items-center">
                    <Header category="Страница" title="Документы" />
                </div>
                <div className="bg-white dark:bg-gray-700 py-2 px-3 border-b dark:border-gray-700">
                    <nav className="flex flex-wrap gap-4">
                        {links.map((link) => (
                            <NavLink
                                key={link.name}
                                to={`/docs/${link.path}`}
                                onMouseEnter={() => setHoveredLink(link.name)}
                                onMouseLeave={() => setHoveredLink(null)}
                                style={({ isActive }) => getNavLinkStyle({isActive, name: link.name})}
                                className="inline-flex whitespace-nowrap border-b-2 border-transparent py-2 px-3 text-sm font-medium text-gray-600 dark:text-gray-300 transition-all duration-200 ease-in-out hover:border-b-2 hover:text-gray-800 dark:hover:text-white"
                                end
                            >
                                {link.name}
                            </NavLink>
                        ))}
                    </nav>
                </div>
                <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white dark:bg-gray-800 rounded-3xl">
                    <Outlet />
                </div>
            </div>
        </div>
        </div>
    );
}

export default DocsRootLayout;
