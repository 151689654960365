import React from 'react';
import { List, CategoryItem, ProductGroupSelector } from 'components';

const formatDate = (date) => (date ? new Date(date).toLocaleDateString() : '—');

const CategoriesList = (props) => {
  const filterOptions = [
    {
      key: 'name',
      label: 'Name',
      field: ({ value, onChange, className }) => (
        <input
          type="text"
          value={value}
          placeholder="Search by Name"
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
          key: 'productgroup',
          label: 'product_group',
          field: ({ value, onChange, className }) => (
            <ProductGroupSelector 
              selectedProductGroups={value} 
              onSelect={(productGroup) => onChange([...value, productGroup])}
              onRemove={(id) => onChange(value.filter((productGroup) => productGroup.id !== id))}
              className={className} />
          ),
          initialValue: props.productGroup ? [props.productGroup] : [],
        },
    {
      key: 'created__gte',
      label: 'Created After',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'created__lte',
      label: 'Created Before',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'updated__gte',
      label: 'Updated After',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'updated__lte',
      label: 'Updated Before',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true },
    { key: 'name', label: 'Name' },
    { 
      key: 'products', 
      label: 'Number of Products', 
      component: (item) => <>{item.products?.meta?.size || 0}</> 
    },
    { key: 'created', label: 'Created At', component: (item) => <>{formatDate(item.created)}</> },
    { key: 'updated', label: 'Updated At', component: (item) => <>{formatDate(item.updated)}</> },
  ];

  return (
    <List
      targetUrl={props.targetUrl || "/products/admin-api/categories/"}
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={CategoryItem}
    />
  );
};

export default CategoriesList;
