import React, { useState, useEffect, useRef } from 'react';
import { Header, RightModal } from '../../../components';
import { BASE_URL } from 'data/config';
import { CheckCircle } from 'lucide-react';
import { useStateContext } from 'contexts/ContextProvider';


const SuccessComponent = ({ clientName, amount, date, onClose, currentColor }) => (
  <div className="bg-white p-8 rounded-lg shadow-lg border-t-4 border-green-500 mt-4">
    <div className="flex items-center mb-6">
      <CheckCircle className="text-green-500 mr-3" size={32} />
      <h2 className="text-2xl font-bold" style={{ color: currentColor }}>Бонус успешно списан</h2>
    </div>
    <div className="space-y-4 bg-gray-50 p-4 rounded-md">
      <div className="flex justify-between items-center">
        <span className="text-gray-600">Клиент:</span>
        <span className="font-semibold text-gray-800">{clientName}</span>
      </div>
      <div className="flex justify-between items-center">
        <span className="text-gray-600">Сумма:</span>
        <span className="font-semibold text-green-600">{amount} баллов</span>
      </div>
      <div className="flex justify-between items-center">
        <span className="text-gray-600">Дата:</span>
        <span className="font-semibold text-gray-800">{date}</span>
      </div>
    </div>
    <button
      onClick={onClose}
      className="mt-8 w-full px-6 py-3 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition duration-200 font-semibold text-lg shadow-md hover:shadow-lg"
      style={{ backgroundColor: currentColor }}
    >
      Закрыть
    </button>
  </div>
);



export const AddBonus = ({fetchBonuses}) => {
  const { showToast, token, currentColor } = useStateContext();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [clientData, setClientData] = useState(null);
  const [deductionAmount, setDeductionAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successInfo, setSuccessInfo] = useState(null);
  const deductionAmountRef = useRef(null);

  useEffect(() => {
    const handlePaste = (e) => {
      const pastedText = (e.clipboardData || window.clipboardData).getData('text');
      
      if (/^\d{10,15}$/.test(pastedText)) {
        setPhoneNumber(pastedText);
        fetchClientData(pastedText);
      }
    };

    document.addEventListener('paste', handlePaste);

    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, []);

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleDeductionAmountChange = (e) => {
    setDeductionAmount(e.target.value);
  };

  const fetchClientData = async (phone = phoneNumber) => {
    if (!phone) return;
    setIsLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/parties/admin-api/clients/list?phone=${phone}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch client data');
      }
      const data = await response.json();
      setClientData(data.response);
      if (deductionAmountRef.current) {
        deductionAmountRef.current.focus();
      }
    } catch (error) {
      showToast({
        title: 'Ошибка!',
        content: 'Не удалось получить данные клиента.',
        cssClass: 'e-toast-danger',
        icon: 'e-error toast-icons'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      fetchClientData();
    }
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/pos/admin-api/bonuses`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          phone: phoneNumber,
          amount: deductionAmount
        }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Failed to deduct bonus');
      }
      setSuccessInfo({
        clientName: `${clientData.first_name} ${clientData.last_name} ( ${clientData.phone})`,
        amount: deductionAmount,
        date: new Date().toLocaleString()
      });
      setShowSuccess(true);
      setClientData(null);
      setPhoneNumber('');
      setDeductionAmount('');
      fetchBonuses();
    } catch (error) {
      console.log("error", error);
      showToast({
        title: 'Ошибка!',
        content: error.message,
        cssClass: 'e-toast-danger',
        icon: 'e-error toast-icons'
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  const closeSuccessModal = () => {
    setShowSuccess(false);
    setSuccessInfo(null);
  };
  
  const inputStyle = `mt-1 block w-full rounded-md shadow-sm py-3 px-4 text-lg focus:ring-2 focus:ring-opacity-50 transition-colors duration-200`;
  const labelStyle = `block text-sm font-medium text-gray-700 mb-1`;

  return (
    <>
      <div className="mt-10">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="phoneNumber" className={labelStyle}>
              Номер телефона
            </label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              onKeyPress={handleKeyPress}
              className={`${inputStyle} border-2`}
              style={{ borderColor: currentColor, focusBorderColor: currentColor }}
              required
            />
          </div>
          {clientData && (
            <div className="bg-gray-50 rounded-lg shadow p-6 space-y-4" style={{ borderLeft: `4px solid ${currentColor}` }}>
              <h2 className="text-2xl font-bold mb-4" style={{ color: currentColor }}>Данные клиента</h2>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-gray-500">Имя</p>
                  <p className="text-lg font-semibold">{clientData.first_name} {clientData.last_name}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">ID клиента</p>
                  <p className="text-lg font-semibold">{clientData.id}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Телефон</p>
                  <p className="text-lg font-semibold">{clientData.phone}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Карта лояльности</p>
                  <p className="text-lg font-semibold">{clientData.discount_card}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Всего баллов</p>
                  <p className="text-lg font-semibold" style={{ color: currentColor }}>{clientData.total_points}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Пол</p>
                  <p className="text-lg font-semibold">{clientData.gender === 'male' ? 'Мужской' : 'Женский'}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Дата рождения</p>
                  <p className="text-lg font-semibold">{clientData.birthday || 'Не указано'}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Адрес пользователя</p>
                  <p className="text-lg font-semibold">{clientData.default_user_address || 'Не указано'}</p>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Адрес магазина</p>
                  <p className="text-lg font-semibold">{clientData.default_store_address || 'Не указано'}</p>
                </div>
              </div>
            </div>
          )}
          {clientData && (
            <div>
              <label htmlFor="deductionAmount" className={labelStyle}>
                Сумма списания
              </label>
              <input
                type="number"
                id="deductionAmount"
                name="deductionAmount"
                value={deductionAmount}
                onChange={handleDeductionAmountChange}
                ref={deductionAmountRef}
                className={`${inputStyle} border-2`}
                style={{ borderColor: currentColor, focusBorderColor: currentColor }}
                required
              />
            </div>
          )}
          <button
            type="submit"
            disabled={isLoading || !clientData}
            className="w-full px-4 py-3 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-opacity-50 disabled:opacity-50 transition duration-200 text-lg"
            style={{ backgroundColor: currentColor }}
          >
            {isLoading ? 'Обработка...' : 'Списать бонус'}
          </button>
        </form>
      </div>
      {showSuccess && (
        <SuccessComponent 
          clientName={successInfo.clientName}
          amount={successInfo.amount}
          date={successInfo.date}
          onClose={closeSuccessModal}
          currentColor={currentColor}
        />
      )}
    </>
  );
};

export default AddBonus;