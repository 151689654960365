import React, { useState } from 'react';
import { Search, Settings, RotateCcw, ChevronDown } from 'lucide-react'
const ProductsTable = () => {
  const [products] = useState([
    { name: 'Кофта с принтом Baby Carters 6-24мес (неск. расцв, 280шт)', code: '51062', barcode: '20510620000000', unit: '', price: 699.00, discount: 0, offPrice: 0, balance1: 28, balance2: 25, balance3: 28, balance4: 97 },
    { name: '**Пенал однотон FrogFree с веревочкой (120шт)', code: '50858', barcode: '20508580000002', unit: '', price: 119.00, discount: 0, offPrice: 0, balance1: 13, balance2: 5, balance3: 0, balance4: 3 },
    { name: '**Пенал однотон FrogFree с веревочкой (120шт)', code: '50858', barcode: '20508580000002', unit: '', price: 119.00, discount: 0, offPrice: 0, balance1: 13, balance2: 5, balance3: 0, balance4: 3 },
    { name: '**Пенал однотон FrogFree с веревочкой (120шт)', code: '50858', barcode: '20508580000002', unit: '', price: 119.00, discount: 0, offPrice: 0, balance1: 13, balance2: 5, balance3: 0, balance4: 3 },
    { name: '**Пенал однотон FrogFree с веревочкой (120шт)', code: '50858', barcode: '20508580000002', unit: '', price: 119.00, discount: 0, offPrice: 0, balance1: 13, balance2: 5, balance3: 0, balance4: 3 },
    // Add more product data as needed
  ]);

  const [selectedCount, setSelectedCount] = useState(4);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  return (
    <div className="p-4">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
            <div className="relative">
                <input type="text" placeholder="Поиск по наименовани" className="pl-8 pr-2 py-1 border rounded" />
                <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
            </div>
            <div className="relative ml-2">
                <button 
                onClick={toggleDropdown}
                className="px-2 py-1 bg-gray-200 rounded flex items-center"
                >
                <span>Выделено {selectedCount}</span>
                <ChevronDown className="ml-1" size={16} />
                </button>
                {isDropdownOpen && (
                <div className="absolute top-full left-0 mt-1 w-48 bg-white border rounded shadow-lg z-10">
                    <button className="w-full text-left px-4 py-2 hover:bg-gray-100">Создать документ</button>
                    <div className="px-4 py-2 font-bold">РАБОТА С ГРУППОЙ ТОВАРОВ</div>
                    <button className="w-full text-left px-4 py-2 hover:bg-gray-100">Цены и скидки</button>
                    <button className="w-full text-left px-4 py-2 hover:bg-gray-100">Категории и группы</button>
                    <button className="w-full text-left px-4 py-2 hover:bg-gray-100">Другое</button>
                    <div className="border-t my-1"></div>
                    <button className="w-full text-left px-4 py-2 hover:bg-gray-100">Ценники</button>
                    <button className="w-full text-left px-4 py-2 hover:bg-gray-100">Редактор цен</button>
                    <button className="w-full text-left px-4 py-2 hover:bg-gray-100 flex items-center">
                    <span className="mr-2">⚖️</span>Оценка склада
                    </button>
                    <button className="w-full text-left px-4 py-2 hover:bg-gray-100 flex items-center">
                    <span className="mr-2">⚖️</span>Файл для весов
                    </button>
                    <button className="w-full text-left px-4 py-2 hover:bg-gray-100 flex items-center">
                    <span className="mr-2">⬇️</span>Скачать в Excel
                    </button>
                    <button className="w-full text-left px-4 py-2 hover:bg-gray-100 text-red-500">Удалить</button>
                </div>
                )}
            </div>
            </div>

        <div className="flex items-center space-x-2">
          <button className="px-4 py-2 bg-green-500 text-white rounded">Создать товар</button>
          <button className="p-2 border rounded">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
          </button>
          <button className="px-4 py-2 border rounded flex items-center">
            <RotateCcw className="mr-2" size={16} />
            Импорт товаров
          </button>
          <button className="p-2 border rounded">
            <RotateCcw size={20} />
          </button>
          <button className="p-2 border rounded">
            <Settings size={20} />
          </button>
        </div>
      </div>

      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-100">
            <th className="border p-2 text-left">
              <input type="checkbox" className="mr-2" />
              НАИМЕНОВАНИЕ
            </th>
            <th className="border p-2 text-left">КОД</th>
            <th className="border p-2 text-left">ШТРИХ-КОД</th>
            <th className="border p-2 text-left">АРТИКУЛ</th>
            <th className="border p-2 text-left">ЕД.ИЗМ</th>
            <th className="border p-2 text-left">ЦЕНА ПРОДАЖИ</th>
            <th className="border p-2 text-left">СКИДКА %</th>
            <th className="border p-2 text-left">OFF PRICE</th>
            <th className="border p-2 text-left">БАЛАНАН АНАР №1</th>
            <th className="border p-2 text-left">БАЛАНАН Ж\К</th>
            <th className="border p-2 text-left">БАЛАНАН МАСАЛЫ</th>
            <th className="border p-2 text-left">БАЛАНАН СИН Н</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product, index) => (
            <tr key={index} className="hover:bg-gray-50">
              <td className="border p-2">
                <input type="checkbox" className="mr-2" />
                {product.name}
              </td>
              <td className="border p-2">{product.code}</td>
              <td className="border p-2">{product.barcode}</td>
              <td className="border p-2">{product.article}</td>
              <td className="border p-2">{product.unit}</td>
              <td className="border p-2">{product.price.toFixed(2)}</td>
              <td className="border p-2">{product.discount}</td>
              <td className="border p-2">{product.offPrice}</td>
              <td className="border p-2">{product.balance1}</td>
              <td className="border p-2">{product.balance2}</td>
              <td className="border p-2">{product.balance3}</td>
              <td className="border p-2">{product.balance4}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="mt-4 flex justify-between items-center">
        <div>
          Страница: 
          {[1, 2, 3, 4, 5].map((page) => (
            <button key={page} className="mx-1 px-2 py-1 border rounded hover:bg-gray-100">
              {page}
            </button>
          ))}
          <button className="ml-1 px-2 py-1 border rounded hover:bg-gray-100">»</button>
        </div>
      </div>
    </div>
  );
};

export default ProductsTable;