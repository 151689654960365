import React from 'react';
import { X, Edit3, Trash2, Calendar, Tag, Flag, Clock, Users, FileText } from 'lucide-react';

const ProductDetails = () => {
  return (
    <div className="bg-gray-100 p-4">
      <div className="bg-white rounded-lg shadow-md p-6">
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <div className="flex space-x-2">
            <button className="p-2 hover:bg-gray-100 rounded-full">
              <X size={20} />
            </button>
            <button className="px-4 py-2 bg-green-500 text-white rounded-md flex items-center">
              <Edit3 size={16} className="mr-2" />
              Редактировать
            </button>
            <button className="p-2 hover:bg-gray-100 rounded-full">
              <FileText size={20} />
            </button>
          </div>
          <button className="text-red-500 hover:text-red-700">
            <Trash2 size={20} />
            Удалить
          </button>
        </div>

        {/* Product Info */}
        <div className="flex mb-6">
          <div className="w-1/4 bg-gray-200 rounded-lg flex items-center justify-center">
            <img src="/api/placeholder/150/150" alt="Product" className="w-32 h-32 object-cover" />
          </div>
          <div className="w-3/4 pl-6">
            <h2 className="text-xl font-semibold mb-2">товар</h2>
            <h1 className="text-2xl font-bold mb-4">**Ксилофон 8011 (25)</h1>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <p className="text-gray-600">Штрих-код:</p>
                <p>2047923000005</p>
              </div>
              <div>
                <p className="text-gray-600">Артикул:</p>
                <p>—</p>
              </div>
              <div>
                <p className="text-gray-600">Код товара:</p>
                <p>47923</p>
              </div>
            </div>
          </div>
        </div>

        {/* Tabs */}
        <div className="flex border-b mb-4">
          <button className="px-4 py-2 border-b-2 border-blue-500 text-blue-500 font-semibold">Информация</button>
          <button className="px-4 py-2 text-gray-500">История движения</button>
        </div>

        {/* Additional Info */}
        <div className="grid grid-cols-2 gap-4 mb-6">
          <div className="flex items-center">
            <Calendar size={16} className="mr-2 text-gray-400" />
            <span className="text-gray-600 mr-2">Создан</span>
            <span>13 декабря 2023</span>
          </div>
          <div className="flex items-center">
            <Tag size={16} className="mr-2 text-gray-400" />
            <span className="text-gray-600 mr-2">Категории</span>
            <span>Игрушки</span>
          </div>
          <div className="flex items-center">
            <Flag size={16} className="mr-2 text-gray-400" />
            <span className="text-gray-600 mr-2">Страна</span>
            <span>—</span>
          </div>
          <div className="flex items-center">
            <Clock size={16} className="mr-2 text-gray-400" />
            <span className="text-gray-600 mr-2">Срок годности</span>
            <span>—</span>
          </div>
          <div className="flex items-center">
            <Users size={16} className="mr-2 text-gray-400" />
            <span className="text-gray-600 mr-2">Группа</span>
            <span>Товары и услуги</span>
          </div>
          <div className="flex items-center">
            <FileText size={16} className="mr-2 text-gray-400" />
            <span className="text-gray-600 mr-2">Описание</span>
            <span>—</span>
          </div>
        </div>

        {/* Prices */}
        <h3 className="text-lg font-semibold mb-2">ЦЕНЫ</h3>
        <table className="w-full mb-6">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-2 text-left">Цена продажи</th>
              <th className="p-2 text-left">Цена закупки</th>
              <th className="p-2 text-left">Себестоимость</th>
              <th className="p-2 text-left">Наценка</th>
              <th className="p-2 text-left">Маржинальность</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-2">485.00 сом</td>
              <td className="p-2">319.95 сом</td>
              <td className="p-2">319.95 сом</td>
              <td className="p-2">52%</td>
              <td className="p-2">34%</td>
            </tr>
          </tbody>
        </table>

        {/* Inventory */}
        <h3 className="text-lg font-semibold mb-2">СКЛАД</h3>
        <table className="w-full">
          <thead>
            <tr className="bg-gray-100">
              <th className="p-2 text-left">Магазин</th>
              <th className="p-2 text-left">Цена продажи, сом</th>
              <th className="p-2 text-left">Остаток,</th>
              <th className="p-2 text-left">По себестоимости, сом</th>
              <th className="p-2 text-left">По цене продажи, сом</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-2">OFF PRICE</td>
              <td className="p-2">485.00</td>
              <td className="p-2">0</td>
              <td className="p-2">0.00</td>
              <td className="p-2">0.00</td>
            </tr>
            <tr>
              <td className="p-2 text-blue-500">Балапан Анар №4</td>
              <td className="p-2">485.00</td>
              <td className="p-2">0</td>
              <td className="p-2">0.00</td>
              <td className="p-2">0.00</td>
            </tr>
            <tr>
              <td className="p-2 text-blue-500">Балапан ЖА</td>
              <td className="p-2">485.00</td>
              <td className="p-2">1</td>
              <td className="p-2">319.95</td>
              <td className="p-2">485.00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductDetails;