import React, { useState, Suspense } from "react";
import { EmployeeGroupsList, Loading, Tooltip, EmployeeGroupForm } from "components";
import { useRightModal } from 'contexts/RightModalContext';
import { useStateContext } from "contexts/ContextProvider";
import { Info } from 'lucide-react';




const EmployeeGroups = () => {
  const { currentColor } = useStateContext();
  const { openRightModal } = useRightModal();

  const handleAddEmployeeGroup = () => {
    openRightModal({
      component: <EmployeeGroupForm />,
      title: "Добавить отдел",
    });
  };
  return (
    <>
      <Suspense fallback={<Loading/>}>

      <div className="bg-gray-100 py-4 px-6 flex justify-between items-center relative">
        <div className="flex items-center relative">
          <Tooltip content={"Here will be related info"} trigger='click' className="mr-2">
            <button className="text-gray-700 hover:text-gray-900 mr-2 mt-1">
              <Info size={20} />
            </button>
          </Tooltip>
          <h1 className="text-2xl font-bold">EmployeeGroups</h1>
          
        </div>
        <div className="flex items-center">
          <button className=" text-white px-4 py-2 rounded-md hover:" style={{ backgroundColor: currentColor }} onClick={() => handleAddEmployeeGroup()}>
            + Отдел
          </button>
        </div>
      </div>
        
        <EmployeeGroupsList/>
      </Suspense>
    </>
  );
};

export default EmployeeGroups;
