import React, { useState, useEffect } from 'react';
import {
  ChevronLeft,
  ChevronRight,
  ArrowUpDown,
  ArrowUp,
  ArrowDown,
  Settings,
} from 'lucide-react';
import { loadList, exportList } from 'services';
import { useRightModal } from 'contexts/RightModalContext';
import { useStateContext } from 'contexts/ContextProvider';
import { Tooltip } from 'components';

const ColumnToggler = ({ columnStates, setColumnStates }) => {
  const handleToggle = (key) => {
    setColumnStates((prev) =>
      prev.map((col) => (col.key === key ? { ...col, hide: !col.hide } : col))
    );
  };

  return (
    <div className="shadow rounded p-4">
      {columnStates.map(({ key, label, hide }) => (
        <div key={key} className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={!hide}
            onChange={() => handleToggle(key)}
          />
          <label>{label}</label>
        </div>
      ))}
    </div>
  );
};

const ListHeader = ({
  isFilterVisible,
  setIsFilterVisible,
  searchValue,
  setSearchValue,
  exportItems,
  columnStates,
  fetchItems,
  setColumnStates,
  currentColor,
}) => {
  return (
    <div className="flex items-center justify-between gap-2 p-2 bg-white border-b">
      <div className="flex items-center gap-2">
        <button
          onClick={() => setIsFilterVisible(!isFilterVisible)}
          className={`px-3 py-1.5 border rounded ${isFilterVisible ? 'bg-gray-100' : 'bg-white'} hover:bg-gray-50`}
        >
          Фильтр
        </button>
        <input
          type="text"
          placeholder="Поиск..."
          className="max-w-sm px-3 py-1.5 border rounded"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={(e) => (e.key === 'Enter' || e.target.value == '' ) && fetchItems()}
        />
      </div>
      <div className="flex items-center gap-2">
        <button
          onClick={exportItems}
          className="px-4 py-2 text-white rounded"
          style={{ backgroundColor: currentColor }}
        >
          Экспорт
        </button>
        <Tooltip
          content={
            <ColumnToggler
              columnStates={columnStates}
              setColumnStates={setColumnStates}
            />
          }
          trigger="click"
          className="px-4 py-2"
        >
          <Settings />
        </Tooltip>
      </div>
    </div>
  );
};

const ListFilters = ({
  isFilterVisible,
  filterOptions,
  filters,
  handleFilterChange,
  clearFilters,
  fetchItems,
  currentColor,
}) => {
  if (!isFilterVisible) return null;

  return (
    <div className="w-full bg-gray-100 p-4 space-y-4 border rounded-md shadow-sm">
        {/* Filter Controls */}
        <div className="flex gap-2">
            <button
                className="px-3 py-1.5 text-white rounded"
                onClick={fetchItems}
                style={{ backgroundColor: currentColor }}
            >
                Найти
            </button>
            <button 
                className="px-3 py-1.5 bg-white border rounded hover:bg-gray-50" 
                onClick={clearFilters}
            >
                Очистить
            </button>
        </div>

        {/* Rows */}
        <div className="grid grid-cols-4 gap-4">
            {filterOptions.map(({ key, label, field: FieldComponent }) => (
            <div key={key} className="space-y-1">
                <label className="text-sm text-gray-600">{label}</label>
                <FieldComponent
                    value={filters[key]}
                    onChange={(value) => handleFilterChange(key, value)}
                    className="w-full h-8 px-2 border rounded"
                />
            </div>
            ))}
        </div>
      
    </div>
  );
};

const ListTable = ({ items, columnStates, handleSelect, getSortIcon, handleSort }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            {columnStates.map(({ key, label, hide }) =>
              !hide && (
                <th
                  key={key}
                  onClick={() => handleSort(key)}
                  className="px-6 py-3 text-left text-sm font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                >
                  <span className="flex items-center">
                    {label}
                    {getSortIcon(key)}
                  </span>
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {items.map((item, index) => (
            <tr
              key={item.id || index}
              onClick={() => handleSelect(item)}
              className="hover:bg-gray-100 cursor-pointer"
            >
              {columnStates.map(({ key, component, hide }) =>
                !hide && (
                  <td key={key} className="px-6 py-4 text-sm text-gray-500">
                    {component ? component(item) : item[key]}
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Pagination = ({ currentPage, totalPages, totalCount, handlePageChange, limit, handleLimitChange, loading }) => {
  return (
    <div className="flex items-center justify-between px-4">
            <div className="flex items-center space-x-2 text-sm text-gray-700">
              <span>Показать:</span>
              <select
                value={limit}
                onChange={handleLimitChange}
                className="border border-gray-300 rounded px-2 py-1"
                disabled={loading}
              >
                {[10, 20, 50, 100].map(value => (
                  <option key={value} value={value}>{value}</option>
                ))}
              </select>
              <span>из {totalCount} записей</span>
            </div>
    
            <div className="flex items-center space-x-2">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1 || loading}
                className="p-2 rounded hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <ChevronLeft className="w-5 h-5" />
              </button>
              
              <div className="flex items-center space-x-1">
                {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                  let pageNum;
                  if (totalPages <= 5) {
                    pageNum = i + 1;
                  } else if (currentPage <= 3) {
                    pageNum = i + 1;
                  } else if (currentPage >= totalPages - 2) {
                    pageNum = totalPages - 4 + i;
                  } else {
                    pageNum = currentPage - 2 + i;
                  }
                  
                  return (
                    <button
                      key={pageNum}
                      onClick={() => handlePageChange(pageNum)}
                      disabled={loading}
                      className={`px-3 py-1 rounded ${
                        currentPage === pageNum
                          ? 'bg-gray-900 text-white'
                          : 'hover:bg-gray-100'
                      } ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                      {pageNum}
                    </button>
                  );
                })}
              </div>
    
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages || loading}
                className="p-2 rounded hover:bg-gray-100 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <ChevronRight className="w-5 h-5" />
              </button>
            </div>
          </div>
  );
};

const List = ({ targetUrl, filterOptions, columns, ItemComponent, ChartComponent }) => {
  const [filters, setFilters] = useState(
    filterOptions.reduce((acc, option) => {
      acc[option.key] = option.initialValue;
      return acc;
    }, {})
  );
  const [searchValue, setSearchValue] = useState('');
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [items, setItems] = useState([]);
  const [stats, setStats] = useState([]);
  const [columnStates, setColumnStates] = useState(columns);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'desc' });
  const { openRightModal } = useRightModal();
  const { currentColor } = useStateContext();

  const handleSelect = (item) => {
    openRightModal({
      component: <ItemComponent item={item} />,
      title: item.name,
    });
  };

  const constructQueryParams = () => {
    const queryParams = [];

    if (sortConfig.key) {
      queryParams.push(`order=${sortConfig.key},${sortConfig.direction}`);
    }
    if (searchValue) {
      queryParams.push(`search=${searchValue}`);
    }
    const filterParams = [];
    filterOptions.forEach(({ key }) => {
      if (filters[key]) {
        if (Array.isArray(filters[key])) {
          if (filters[key].length > 0) {
            filterParams.push(`${key}=[${filters[key].map((item) => item.id).join(',')}]`);
          }
        } else {
          filterParams.push(`${key}=${filters[key]}`);
        }
      }
    });

    if (filterParams.length > 0) {
      queryParams.push(`filter=${filterParams.join(';')}`);
    }

    queryParams.push(`offset=${offset}`, `limit=${limit}`);

    return queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
  };

  const fetchItems = async () => {
    try {
      console.log('fetchItems');
      setLoading(true);
      const queryString = constructQueryParams();
      const response = await loadList(targetUrl, queryString);
      setItems(response.rows);
      setTotalCount(response.meta.size);
    } catch (error) {
      console.error('Error fetching items:', error);
    } finally {
      setLoading(false);
    }
    fetchStats();
  };

  const fetchStats = async () => {
    try {
      console.log('fetchItems');
      setLoading(true);
      const queryString = constructQueryParams();
      const response = await loadList(`${targetUrl}/stats`, queryString);
      setStats(response);
    } catch (error) {
      console.error('Error fetching items:', error);
    } finally {
      setLoading(false);
    }
  };



  const exportItems = async () => {
    try {
      const queryString = constructQueryParams();
      exportList(`${targetUrl}/export`, queryString);
    } catch (error) {
      console.error('Error exporting items:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchItems();
  }, [offset, limit, sortConfig]);

  const handleFilterChange = (key, value) => {
    console.log('handleFilterChange', key, value);
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const clearFilters = () => {
    setFilters(
      filterOptions.reduce((acc, option) => {
        acc[option.key] = option.initialValue;
        return acc;
      }, {})
    );
    setSearchValue('');
    setOffset(0);
    fetchItems();
  };

  const handleSort = (key) => {
    let direction = 'desc';
    if (sortConfig.key === key && sortConfig.direction === 'desc') {
      direction = 'asc';
    }
    setSortConfig({ key, direction });
  };

  const totalPages = Math.ceil(totalCount / limit);
  const currentPage = Math.floor(offset / limit) + 1;

  const handlePageChange = (newPage) => {
    setOffset((newPage - 1) * limit);
  };

  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value, 10);
    setLimit(newLimit);
    setOffset(0);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) return <ArrowUpDown className="w-4 h-4 ml-1" />;
    return sortConfig.direction === 'desc' ? (
      <ArrowDown className="w-4 h-4 ml-1" />
    ) : (
      <ArrowUp className="w-4 h-4 ml-1" />
    );
  };

  return (
    <div className=" p-4 space-y-4">
      <ListHeader
        isFilterVisible={isFilterVisible}
        setIsFilterVisible={setIsFilterVisible}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        exportItems={exportItems}
        columnStates={columnStates}
        fetchItems={fetchItems}
        setColumnStates={setColumnStates}
        currentColor={currentColor}
      />
      <ListFilters
        isFilterVisible={isFilterVisible}
        filterOptions={filterOptions}
        filters={filters}
        handleFilterChange={handleFilterChange}
        clearFilters={clearFilters}
        fetchItems={fetchItems}
        currentColor={currentColor}
      />
      <ListTable
        items={items}
        columnStates={columnStates}
        handleSelect={handleSelect}
        getSortIcon={getSortIcon}
        handleSort={handleSort}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        totalCount={totalCount}
        handlePageChange={handlePageChange}
        limit={limit}
        handleLimitChange={handleLimitChange}
        loading={loading}
      />
      {ChartComponent && <ChartComponent data={stats} />}
    </div>
  );
};

export default List;
