import { useEffect, useState, useRef } from 'react';
import { Suspense } from 'react';
import { RightModal, ListingsList, ListingItem } from 'components';
import { Await } from 'react-router-dom';
import { useStateContext } from '../../../contexts/ContextProvider';
import { loadListingsList } from './Services';
import { BASE_URL } from 'data/config';

function Listings({ status }) {
  const [selectedListing, setSelectedListing] = useState(null);
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ page: 1, query: "" });
  const debounceTimeout = useRef(null);
  const { rightModal, setRightModal, showToast, token } = useStateContext();
  
  const getTitle = (status) => {
    switch (status) {
      case 'archived': return 'Архивные листинги';
      case 'active': 
      default: return 'Активные листинги';
    }
  };
  const title = getTitle(status);

  const loadListings = async (status = 'active', token, filters) => {
    console.log("loadListings", filters);

    const { page, query } = filters;
    setLoading(true);
    try {
      return loadListingsList(status, token="token", { page, query });
    } catch (error) {
      showToast({ title: 'Error!', content: error.message, cssClass: 'e-toast-danger', icon: 'e-error toast-icons' });
    } finally {
      setLoading(false);
    }
  };

  const loadMoreListings = async () => {
    const nextPage = filters.page + 1;
    setFilters({ ...filters, page: nextPage });
    const loadedListings = await loadListings(status, token, { ...filters, page: nextPage });
    if (Array.isArray(loadedListings)) {
      setListings((prev) => [...prev, ...loadedListings]);
    } else {
      console.error('Loaded listings is not an array:', loadedListings);
    }
  };

  const handleQueryChange = async (event) => {
    const newQuery = event.target.value;
    setFilters({ page: 1, query: newQuery });
    clearTimeout(debounceTimeout.current);
    debounceTimeout.current = setTimeout(async () => {
      const loadedListings = await loadListings(status, token, { page: 1, query: newQuery });
      setListings(loadedListings);
    }, 2000);
  };

  useEffect(() => {
    if (token) {
      loadListings(status, token, { page: 1, query: ""}).then(loadedListings => setListings(loadedListings));
    }
  }, [status, token]);

  return (
    <>
      <Suspense fallback={<p className="flex flex-wrap">Loading...</p>}>
        <>
          <input
            type="text"
            value={filters.query}
            onChange={handleQueryChange}
            placeholder="Search..."
            className="search-input float-right mb-4 p-2 border rounded-lg shadow-md"
          />
          <ListingsList 
            listings={listings} 
            title={title} 
            selectHandler={(listing) => {
              setSelectedListing(listing);  
              setRightModal(true);
            }}
          />
          <button onClick={loadMoreListings} className="load-more-button mt-4 p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700" disabled={loading}>
            {loading ? 'Загрузка...' : 'Загрузить еще'}
          </button>
        </>
      </Suspense>
      {rightModal && selectedListing &&
        <RightModal title={"Листинг: " + selectedListing.title} afterClose={() => setSelectedListing(null)}>
          <Suspense fallback={<p style={{ textAlign: 'center' }}>Loading...</p>}>
            <Await resolve={selectedListing}>
              {(loadedListing) => <ListingItem listing={loadedListing} />}
            </Await>
          </Suspense>
          <h1 className="flex md:flex-row flex-col">Here will be related statistics and more info...</h1>
        </RightModal>
      }
    </>
  );
}

export default Listings;
