import React from 'react';
import { EmployeeGroupSelector, StoreSelector, List, EmployeeItem } from 'components';


const formatDate = (date) => new Date(date).toLocaleDateString();

const EmployeesList = (props) => {
  const filterOptions = [
    {
      key: 'name',
      label: 'Name',
      field:  (props) => (
        <input 
          type="text"
          placeholder="Наименование"
          value={props.value}
          className={props.className}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      initialValue: ''
    },
    {
      key: 'group',
      label: 'Groups',
      field: ({value, onChange, className}) => (
        <EmployeeGroupSelector
          selectedEmployeeGroups={value}
          onSelect={(group) => onChange([...value, group])}
          onRemove={(id) => onChange(value.filter((group) => group.id !== id))}
          placeholder="Select groups..."
          className={className}
        />
      ),
      initialValue: props.group ? [props.group] : [],
    },
    {
      key: 'stores',
      label: 'Stores',
      field: ({value, onChange, className}) => (
        <StoreSelector
          selectedStores={value}
          onSelect={(store) => onChange([...value, store])}
          onRemove={(id) => onChange(value.filter((store) => store.id !== id))}
          className={className}
        />
      ),
      initialValue: props.stores ? [props.stores] : [],
    },
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true},
    { key: 'username', label: 'Username' },
    { key: 'name', label: 'Full Name', component: (item) => <>{item.first_name} {item.last_name}</> },
    { key: 'stores', label: 'Stores', component: (item) => <>{item.stores && item.stores.map((store) => store.name).join(', ')}</> },
    { key: 'group', label: 'Group', component: (item) => <>{item.group && item.group.name}</> },
    { key: 'last_seen', label: 'Last Seen', component: (item) => <>{formatDate(item.last_seen)}</> },
    { key: 'created', label: 'Created', component: (item) => <>{formatDate(item.created)}</> },
  ];


  return (
    <List
      targetUrl="/admin-api/employees"
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={EmployeeItem}
    />
  );
};

export default EmployeesList;
