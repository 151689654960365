import React from 'react';
import { List, DocItem, ClientSelector, SupplierSelector, EmployeeSelector, StoreSelector } from 'components';
import { default as DailyStatsCharts } from './Chart.js';

const formatDate = (date) => (date ? new Date(date).toLocaleDateString() : '—');

const DocsList = (props) => {
  const filterOptions = [
    {
      key: 'client',
      label: 'Client',
      field: ({ value, onChange, className }) => (
        <ClientSelector value={value} onChange={onChange} className={className} />
      ),
      initialValue: props.client ? [props.client] : [],
    },
    {
      key: 'supplier',
      label: 'Supplier',
      field: ({ value, onChange, className }) => (
        <SupplierSelector value={value} onChange={onChange} className={className} />
      ),
      initialValue: props.supplier ? [props.supplier] : [],
    },
    {
      key: 'employee',
      label: 'Employee',
      field: ({ value, onChange, className }) => (
        <EmployeeSelector 
          value={value} 
          onSelect={(employee) => onChange([...value, employee])}
          onRemove={(id) => onChange(value.filter((group) => group.id !== id))}
          className={className} 
        />
      ),
      initialValue: props.employee ? [props.employee] : [],
    },
    {
      key: 'from_store',
      label: 'From Store',
      field: ({ value, onChange, className }) => (
        <StoreSelector
          selectedStores={value}
          onSelect={(store) => onChange([...value, store])}
          onRemove={(id) => onChange(value.filter((store) => store.id !== id))}
          className={className}
        />
      ),
      initialValue: props.stores ? [props.stores] : [],
    },
    {
      key: 'to_store',
      label: 'To Store',
      field: ({ value, onChange, className }) => (
        <StoreSelector
          selectedStores={value}
          onSelect={(store) => onChange([...value, store])}
          onRemove={(id) => onChange(value.filter((store) => store.id !== id))}
          className={className}
        />
      ),
      initialValue: props.stores ? [props.stores] : [],
    },
    {
      key: 'date__gte',
      label: 'Date (From)',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'date__lt',
      label: 'Date (To)',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'is_kkm',
      label: 'KKM',
      field: ({ value, onChange, className }) => (
        <select
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        >
          <option value="">All</option>
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      ),
      initialValue: '',
    },
    {
      key: 'style',
      label: 'Style',
      field: ({ value, onChange, className }) => (
        <select
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        >
          <option value="">All</option>
          <option value="sales">Продажа</option>
          <option value="return_sales">Возврат продажи</option>
          <option value="movements">Перемещение</option>
          <option value="purchases">Закупка</option>
          <option value="return_purchases">Возврат закупки</option>
          <option value="changes">Изменения</option>
        </select>
      ),
      initialValue: '',
    },
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true },
    { key: 'number', label: 'Number' },
    { key: 'style', label: 'Style' },
    { key: 'from_store', label: 'From Store', component: (item) => <>{item.from_store?.meta.name}</> },
    { key: 'to_store', label: 'To Store', component: (item) => <>{item.to_store?.meta.name}</> },
    { key: 'client', label: 'Client', component: (item) => <>{item.client?.meta.name}</> },
    { key: 'supplier', label: 'Supplier', component: (item) => <>{item.supplier?.meta.name}</> },
    { key: 'quantity', label: 'Quantity' },
    { key: 'sum', label: 'Sum' },
    { key: 'profit', label: 'Profit' },
    { key: 'date', label: 'Date', component: (item) => <>{formatDate(item.date)}</> },
    { key: 'created', label: 'Created At', component: (item) => <>{formatDate(item.created)}</> },
    { key: 'updated', label: 'Updated At', component: (item) => <>{formatDate(item.updated)}</> },
  ];

  return (
    <List
      targetUrl={props.targetUrl || "/docs/admin-api/docs"}
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={DocItem}
      ChartComponent={DailyStatsCharts}
    />
  );
};

export default DocsList;
