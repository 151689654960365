import React, { useState, useEffect, Suspense, useCallback } from 'react';
import { DocsList, RightModal, DocItem, DateRangePicker } from 'components';
import { loadDocsList } from './Services';
import { useStateContext } from 'contexts/ContextProvider'


const setConsultants = () => {
  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({'consultant' : -1, 'styles': ['sales']});
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { token, showToast } = useStateContext();

  const formatDate = (date) => {
    return date ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}` : '';
  };



  async function fetchDocs(page = 1) {
    try {
      console.log('Fetching docs...', filters, page);
      const docsData = await loadDocsList(token, page, filters);
      console.log(docsData);
      if (page === 1) {
        setDocs(docsData.response);
      } else {
        setDocs((prevDocs) => [...prevDocs, ...docsData.response]);
      }
      setTotalPages(docsData.total_pages);
    } catch (error) {
      showToast({
        title: 'Ошибка!',
        content: error.message,
        cssClass: 'e-toast-danger',
        icon: 'e-error toast-icons'
      });
    } finally {
      setLoading(false);
    }
  }

  const loadMoreDocs = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
  };


  useEffect(() => {
    setLoading(true);
    fetchDocs(currentPage);
  }, [token, currentPage, filters]);

  const handleDocChoose = (doc) => {
    setSelectedDoc(doc);
  };

  const onDateRangeApply = ({startDate, endDate}) => {
    setCurrentPage(1);

    setFilters({ ...filters, start_date: formatDate(startDate), end_date: formatDate(endDate) });
  };


  return (
    <>
      <DateRangePicker onApply={onDateRangeApply} initialStartDate={filters.start_date ? new Date(filters.start_date) : null} initialEndDate={filters.end_date ? new Date(filters.end_date): null}/>

      <DocsList docs={docs} onChoose={handleDocChoose}/>
      {currentPage < totalPages && (
          <div className="p-4 text-center">
            <button
              onClick={loadMoreDocs}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Load More'}
            </button>
          </div>
        )}
      { selectedDoc && (
        <RightModal title={"Документ: " + selectedDoc.number} afterClose={()=> setSelectedDoc(null)}>
          <Suspense fallback={<p style={{ textAlign: 'center' }}>Загрузка...</p>}>
            <DocItem doc={selectedDoc}/>
          </Suspense>
        </RightModal>
      )}
    </>
    
  )
};

export default setConsultants