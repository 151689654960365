import React, { useState } from "react";
import {
  LineChart,
  BarChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// Helper function to format dates in Russian
const formatRussianDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("ru", { month: "long" });
  return `${day}-${month}`;
};

// Custom Tooltip Component
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const total = payload.reduce((sum, entry) => sum + entry.value, 0);
    return (
      <div className="bg-white p-4 border rounded-lg shadow-md">
        <p className="font-semibold">{label}</p>
        {payload.map((entry, index) => {
          const percentage = ((entry.value / total) * 100).toFixed(1) + "%";
          return (
            <p key={index} style={{ color: entry.color }}>
              {entry.name}: {entry.value} ({percentage})
            </p>
          );
        })}
      </div>
    );
  }
  return null;
};

const DailyStatsCharts = ({ data }) => {
  const [visibleStats, setVisibleStats] = useState({
    day_sum: true,
    day_purchase: true,
    day_profit: true,
    day_discount_sum: true,
    day_bonus_cashback: true,
  });

  const toggleStat = (stat) => {
    setVisibleStats((prev) => ({ ...prev, [stat]: !prev[stat] }));
  };

  // Format dates in Russian
  const dataWithRussianDates = data.map((item) => ({
    ...item,
    date: formatRussianDate(item.date),
  }));

  return (
    <div className="p-6 bg-gray-50 rounded-lg shadow-md">
      {/* Toggle Buttons */}
      <div className="flex flex-wrap gap-2 mb-6">
        {Object.keys(visibleStats).map((stat) => (
          <button
            key={stat}
            onClick={() => toggleStat(stat)}
            className={`px-4 py-2 rounded-md text-sm font-medium ${
              visibleStats[stat]
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            {stat === "day_sum" && "Сумма"}
            {stat === "day_purchase" && "Закупка"}
            {stat === "day_profit" && "Прибыль"}
            {stat === "day_discount_sum" && "Скидка"}
            {stat === "day_bonus_cashback" && "Бонусный кэшбэк"}
          </button>
        ))}
      </div>

      {/* Line Chart */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Ежедневная статистика - Линейный график</h2>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={dataWithRussianDates}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            {visibleStats.day_sum && (
              <Line
                type="monotone"
                dataKey="day_sum"
                name="Сумма"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
            )}
            {visibleStats.day_purchase && (
              <Line
                type="monotone"
                dataKey="day_purchase"
                name="Закупка"
                stroke="#82ca9d"
                activeDot={{ r: 8 }}
              />
            )}
            {visibleStats.day_profit && (
              <Line
                type="monotone"
                dataKey="day_profit"
                name="Прибыль"
                stroke="#ffc658"
                activeDot={{ r: 8 }}
              />
            )}
            {visibleStats.day_discount_sum && (
              <Line
                type="monotone"
                dataKey="day_discount_sum"
                name="Скидка"
                stroke="#ff7300"
                activeDot={{ r: 8 }}
              />
            )}
            {visibleStats.day_bonus_cashback && (
              <Line
                type="monotone"
                dataKey="day_bonus_cashback"
                name="Бонусный кэшбэк"
                stroke="#a4de6c"
                activeDot={{ r: 8 }}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>

      {/* Stacked Bar Chart */}
      <div>
        <h2 className="text-xl font-semibold mb-4">Ежедневная статистика - Столбчатая диаграмма</h2>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={dataWithRussianDates}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Bar dataKey="day_sum" name="Сумма" stackId="a" fill="#8884d8" />
            <Bar dataKey="day_purchase" name="Закупка" stackId="a" fill="#82ca9d" />
            <Bar dataKey="day_profit" name="Прибыль" stackId="a" fill="#ffc658" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default DailyStatsCharts;