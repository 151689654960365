import React, { useState, useEffect} from 'react';
import { Calendar, DollarSign, User, Store, ShoppingCart } from 'lucide-react';
import { PositionsList } from 'components';
import { useStateContext } from 'contexts/ContextProvider';

const DocItem = ({ item }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4 max-w-3xl mx-auto text-sm">
      <div className="flex justify-between items-center mb-3">
        <h2 className="text-base font-medium text-blue-600">Продажа #{item.number}</h2>
        <span className="bg-green-500 text-white px-2 py-0.5 rounded text-xs">
          Документ проведён
        </span>
      </div>

      <div className="grid grid-cols-2 gap-3 mb-4">
        <div className="flex items-center">
          <Calendar className="w-4 h-4 mr-2 text-gray-500" />
          <span className="text-sm">Создан <p className='text-blue-600 inline'>{new Date(item.created).toLocaleDateString()}</p></span>
        </div>
        <div className="flex items-center">
          <DollarSign className="w-4 h-4 mr-2 text-gray-500" />
          <span className="text-sm">Сумма: <p className='text-blue-600 inline'>{item.sum} сом</p></span>
        </div>
        <div className="flex items-center">
          <Store className="w-4 h-4 mr-2 text-gray-500" />
          <span className="text-sm">ОТПРАВИТЕЛЬ: 
            <p className="text-blue-600 inline">{item.from ? item.from.meta.name : ' -  ' }</p>
          </span>
        </div>
        <div className="flex items-center">
          <User className="w-4 h-4 mr-2 text-gray-500" />
          <span className="text-sm">ПОЛУЧАТЕЛЬ:
          <p className="text-blue-600 inline">{item.to ? item.to.meta.name : ' -  ' }</p>
          </span>
        </div>
      </div>

      <div className="mb-4">
        <h3 className="text-sm font-medium mb-2">ОПЛАТА</h3>
        <table className="w-full text-xs">
          <thead>
            <tr className="bg-gray-100">
              <th className="text-left p-1.5">#</th>
              <th className="text-left p-1.5">Счёт</th>
              <th className="text-left p-1.5">Контрагент</th>
              <th className="text-left p-1.5">Дата</th>
              <th className="text-right p-1.5">Сумма</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-1.5 text-blue-600">•</td>
              <td className="p-1.5 text-blue-600">{item.from ? item.from.meta.name : ' - '}</td>
              <td className="p-1.5 text-blue-600">{item.to ? item.to.meta.name : ' - '}</td>
              <td className="p-1.5 text-blue-600">{new Date(item.date).toLocaleDateString()}</td>
              <td className="p-1.5 text-blue-600 text-right">{item.sum} сом</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="mb-4">
        <h3 className="text-sm font-medium mb-2">ПОЗИЦИИ</h3>
        <PositionsList filterString={'doc=' + item.id} />
      </div>
    </div>
  );
};

export default DocItem;