import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { loadList } from 'services';
import { useStateContext } from 'contexts/ContextProvider';
import { BASE_URL } from 'data/config';
import { Link, useNavigate } from 'react-router-dom';
import { MoreVertical, Archive, Edit } from 'lucide-react';

const Consultants = () => {
  const [consultants, setConsultants] = useState({});
  const [loading, setLoading] = useState(true);
  const { showToast } = useStateContext();
  const navigate = useNavigate();


  useEffect(() => {
    async function fetchConsultants() {
      try {
        const consultantsData = await loadList("/admin-api/consultants", "", "token"); 
        const storeMap = consultantsData.reduce((acc, consultant) => {
          if (consultant.main_store) {
            const storeId = consultant.main_store.id;
            if (!acc[storeId]) {
              acc[storeId] = { name: consultant.main_store.name, consultants: [] };
            }
            acc[storeId].consultants.push(consultant);
          } else {
            if (!acc[-1]) {
              acc[-1] = { name: "Unassigned Consultants", consultants: [] };
            }
            acc[-1].consultants.push(consultant);
          }
          return acc;
        }, {});
        setConsultants(storeMap);
      } catch (error) {
        showToast({
          title: 'Ошибка!',
          content: error.message,
          cssClass: 'e-toast-danger',
          icon: 'e-error toast-icons'
        });
      } finally {
        setLoading(false);
      }
    }

    fetchConsultants();
  }, [showToast]);

  const updateConsultantStore = async (consultantId, storeId) => {
    const formData = new FormData();
    formData.append('consultant_id', consultantId);
    formData.append('store_id', storeId);

    try {
      const response = await fetch(`${BASE_URL}/pos/admin-api/consultants`, {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer token', // Replace 'token' with your actual token
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Не удалось обновить магазин консультанта');
      }

      showToast({
        title: 'Успех',
        content: 'Магазин консультанта успешно обновлен',
        cssClass: 'e-toast-success',
        icon: 'e-success toast-icons'
      });
    } catch (error) {
      throw new Error('Не удалось обновить магазин консультанта');
    }
  };

  const archiveConsultant = async (id, is_archived) => {
    const confirmArchive = window.confirm("Are you sure you want to archive this consultant?");
    if (confirmArchive) {
      try {
        const url = `${BASE_URL}/admin-api/employees`; 
        const token = "token"; // Replace with your actual token

        const formData = new FormData();
        formData.append("id", id);
        formData.append("is_archived", is_archived);
        
        const response = await fetch(url, {
          method: "PUT", 
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
  
        if (response.ok) {
          showToast({ title: 'Success!', content: 'Consultant has been successfully archived.', cssClass: 'e-toast-success', icon: 'e-success toast-icons' });
          navigate(is_archived ? "/company/employees/archived" : "/company/employees/"); 
        } else {
          const errorData = await response.json();
          showToast({ title: 'Error!', content: errorData.message || 'Could not archive consultant.', cssClass: 'e-toast-danger', icon: 'e-error toast-icons' });
        }
      } catch (error) {
        console.error("Fetch error:", error);
        showToast({ title: 'Error!', content: 'Failed to communicate with server.', cssClass: 'e-toast-danger', icon: 'e-error toast-icons' });
      }
    }
  };

  const onDragEnd = async (result) => {
    const { source, destination, draggableId } = result;
    if (!destination) return;

    const sourceStoreId = parseInt(source.droppableId);
    const destStoreId = parseInt(destination.droppableId);

    if (sourceStoreId !== destStoreId) {
      const sourceConsultants = Array.from(consultants[sourceStoreId].consultants);
      const destConsultants = destStoreId === -1
        ? Array.from(consultants[-1]?.consultants || [])
        : Array.from(consultants[destStoreId].consultants);
      
      const [movedConsultant] = sourceConsultants.splice(source.index, 1);
      
      try {
        await updateConsultantStore(movedConsultant.id, destStoreId);
        
        // Update the consultant's main_store
        movedConsultant.main_store = destStoreId === -1 ? null : { id: destStoreId, name: consultants[destStoreId].name };
        
        destConsultants.splice(destination.index, 0, movedConsultant);

        setConsultants(prevConsultants => {
          const updatedConsultants = { ...prevConsultants };
          
          // Update source store
          updatedConsultants[sourceStoreId] = {
            ...updatedConsultants[sourceStoreId],
            consultants: sourceConsultants
          };
          
          // Update destination store
          if (destStoreId === -1) {
            updatedConsultants[-1] = updatedConsultants[-1] || { name: "Unassigned Consultants", consultants: [] };
            updatedConsultants[-1].consultants = destConsultants;
          } else {
            updatedConsultants[destStoreId] = {
              ...updatedConsultants[destStoreId],
              consultants: destConsultants
            };
          }
          
          return updatedConsultants;
        });
      } catch (error) {
          showToast({
            title: 'Ошибка!',
            content: error.message,
            cssClass: 'e-toast-danger',
            icon: 'e-error toast-icons'
          });
        }
      }
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  const sortedStoreIds = Object.keys(consultants)
    .map(Number)
    .sort((a, b) => a - b)
    .filter(id => id !== -1);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-4">Consultants by Store</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {sortedStoreIds.map(storeId => (
            <Droppable key={storeId} droppableId={storeId.toString()}>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="bg-white shadow-md rounded-lg p-4"
                >
                  <h2 className="text-lg font-semibold mb-2">{consultants[storeId].name}</h2>
                  {consultants[storeId].consultants.map((consultant, index) => (
                    <Draggable key={consultant.id} draggableId={consultant.id.toString()} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="bg-gray-100 p-2 mb-2 rounded cursor-move flex items-center justify-between"
                        >
                          <div className="flex items-center">
                            <img src={consultant.image_url} alt={consultant.name} className="w-8 h-8 rounded-full mr-2" />
                            <span>{consultant.name}</span>
                          </div>
                          <div className="relative group">
                            <button className="p-1 rounded-full hover:bg-gray-200">
                              <MoreVertical size={16} />
                            </button>
                            <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 ease-in-out">
                              <Link
                                to={`/company/employees/${consultant.id}/edit/`}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                <Edit size={16} className="inline mr-2" />
                                Edit
                              </Link>
                              <button
                                onClick={() => archiveConsultant(consultant.id, !consultant.is_archived)}
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                <Archive size={16} className="inline mr-2" />
                                {consultant.is_archived ? 'Unarchive' : 'Archive'}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
          <Droppable droppableId="-1">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="bg-white shadow-md rounded-lg p-4"
              >
                <h2 className="text-lg font-semibold mb-2">Unassigned Consultants</h2>
                {consultants[-1]?.consultants.map((consultant, index) => (
                  <Draggable key={consultant.id} draggableId={consultant.id.toString()} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="bg-gray-100 p-2 mb-2 rounded cursor-move flex items-center justify-between"
                      >
                        <div className="flex items-center">
                          <img src={consultant.image_url} alt={consultant.name} className="w-8 h-8 rounded-full mr-2" />
                          <span>{consultant.name}</span>
                        </div>
                        <div className="relative group">
                          <button className="p-1 rounded-full hover:bg-gray-200">
                            <MoreVertical size={16} />
                          </button>
                          <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg hidden group-hover:block">
                            <Link
                              to={`/company/employees/${consultant.id}/edit/`}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              <Edit size={16} className="inline mr-2" />
                              Edit
                            </Link>
                            <button
                              onClick={() => archiveConsultant(consultant.id, !consultant.is_archived)}
                              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              <Archive size={16} className="inline mr-2" />
                              {consultant.is_archived ? 'Unarchive' : 'Archive'}
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  );
};

export default Consultants;