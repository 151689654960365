import React from 'react';
import { List, ProductItem, SupplierSelector, CategorySelector, ProductGroupSelector } from 'components';

const formatDate = (date) => (date ? new Date(date).toLocaleDateString() : '—');

const ProductsList = (props) => {
  const filterOptions = [
    {
      key: 'supplier',
      label: 'Supplier',
      field: ({ value, onChange, className }) => (
        <SupplierSelector 
          selectedSuppliers={value} 
          onSelect={(supplier) => onChange([...value, supplier])}
          onRemove={(id) => onChange(value.filter((supplier) => supplier.id !== id))}
          className={className} 
        />
      ),
      initialValue: props.supplier ? [props.supplier] : [],
    },
    {
      key: 'categories',
      label: 'Category',
      field: ({ value, onChange, className }) => (
        <CategorySelector 
          selectedCategories={value} 
          onSelect={(category) => onChange([...value, category])}
          onRemove={(id) => onChange(value.filter((category) => category.id !== id))}
          className={className} />
      ),
      initialValue: props.category ? [props.category] : [],
    },
    {
      key: 'productgroup',
      label: 'product_group',
      field: ({ value, onChange, className }) => (
        <ProductGroupSelector 
          selectedProductGroups={value} 
          onSelect={(productGroup) => onChange([...value, productGroup])}
          onRemove={(id) => onChange(value.filter((productGroup) => productGroup.id !== id))}
          className={className} />
      ),
      initialValue: props.productGroup ? [props.productGroup] : [],
    },
    {
      key: 'price__gte',
      label: 'Price (Min)',
      field: ({ value, onChange, className }) => (
        <input
          type="number"
          value={value}
          placeholder="Min Price"
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'price__lte',
      label: 'Price (Max)',
      field: ({ value, onChange, className }) => (
        <input
          type="number"
          value={value}
          placeholder="Max Price"
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'created__gte',
      label: 'Created After',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
    {
      key: 'created__lte',
      label: 'Created Before',
      field: ({ value, onChange, className }) => (
        <input
          type="date"
          value={value}
          className={className}
          onChange={(e) => onChange(e.target.value)}
        />
      ),
      initialValue: '',
    },
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true },
    { key: 'name', label: 'Name' },
    { key: 'barcode', label: 'Barcode' },
    { key: 'code', label: 'Code' },
    { key: 'price', label: 'Price' },
    { key: 'cost', label: 'Cost' },
    { key: 'supplier', label: 'Supplier', component: (item) => <>{item.supplier?.name}</> },
    { key: 'discount', label: 'Discount' },
    { key: 'created', label: 'Created At', component: (item) => <>{formatDate(item.created)}</> },
    { key: 'updated', label: 'Updated At', component: (item) => <>{formatDate(item.updated)}</> },
  ];

  return (
    <List
      targetUrl={props.targetUrl || "/products/admin-api/products/"}
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={ProductItem}
    />
  );
};

export default ProductsList;
