import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { ChevronDown, X } from 'lucide-react';


const DateRangePickerUtil = ({ initialStartDate, initialEndDate, onApply }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);




  const months = [
    'ЯНВАРЬ', 'ФЕВРАЛЬ', 'МАРТ', 'АПРЕЛЬ', 'МАЙ', 'ИЮНЬ',
    'ИЮЛЬ', 'АВГУСТ', 'СЕНТЯБРЬ', 'ОКТЯБРЬ', 'НОЯБРЬ', 'ДЕКАБРЬ'
  ];

  const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

  const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();

  const generateDateRangePickerDays = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const daysInMonth = getDaysInMonth(year, month);
    const firstDayOfMonth = new Date(year, month, 1).getDay();
    const days = [];

    for (let i = 0; i < (firstDayOfMonth + 6) % 7; i++) {
      days.push(null);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      days.push(new Date(year, month, i));
    }

    return days;
  };

  const handleDateClick = (date) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(date);
      setEndDate(null);
    } else if (date > startDate) {
      setEndDate(date);
    } else {
      setEndDate(startDate);
      setStartDate(date);
    }
  };

  const setDateRange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
    setCurrentDate(start);
  };

  const handlePresetClick = (preset) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let start, end;

    switch (preset) {
      case 'сегодня':
        setDateRange(today, today);
        break;
      case 'Вчера':
        start = new Date(today);
        start.setDate(start.getDate() - 1);
        setDateRange(start, start);
        break;
      case '7 дней':
        end = new Date(today);
        start = new Date(today);
        start.setDate(start.getDate() - 6);
        setDateRange(start, end);
        break;
      case '30 дней':
        end = new Date(today);
        start = new Date(today);
        start.setDate(start.getDate() - 29);
        setDateRange(start, end);
        break;
      case 'Этот месяц':
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        setDateRange(start, end);
        break;
      case 'Прошлый месяц':
        start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        end = new Date(today.getFullYear(), today.getMonth(), 0);
        setDateRange(start, end);
        break;
      case 'квартал':
        const quarter = Math.floor(today.getMonth() / 3);
        start = new Date(today.getFullYear(), quarter * 3, 1);
        end = new Date(today.getFullYear(), (quarter + 1) * 3, 0);
        setDateRange(start, end);
        break;
      default:
        break;
    }
  };


  const handlePrevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const isDateInRange = (date) => {
    return startDate && endDate && date >= startDate && date <= endDate;
  };

  const formatDate = (date) => {
    return date ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}` : '';
  };

  

  return (
    <div className="p-4">
      <div className="flex font-sans">
        <div className="w-48 bg-gray-100 p-4">
          <ul className="space-y-2">
            {['сегодня', 'Вчера', '7 дней', '30 дней', 'Этот месяц', 'Прошлый месяц', 'квартал'].map((preset) => (
              <li 
                key={preset} 
                className="cursor-pointer text-blue-600 hover:text-blue-800"
                onClick={() => handlePresetClick(preset)}
              >
                {preset}
              </li>
            ))}
          </ul>
        </div>
        <div className="flex-1 p-4">
          <div className="flex justify-between items-center mb-4">
            <button onClick={handlePrevMonth} className="p-2">
              <ChevronLeft size={24} />
            </button>
            <h2 className="text-xl font-semibold">
              {months[currentDate.getMonth()]} {currentDate.getFullYear()}
            </h2>
            <button onClick={handleNextMonth} className="p-2">
              <ChevronRight size={24} />
            </button>
          </div>
          <div className="grid grid-cols-7 gap-1">
            {daysOfWeek.map(day => (
              <div key={day} className="text-center font-semibold">{day}</div>
            ))}
            {generateDateRangePickerDays().map((date, index) => (
              <div
                key={index}
                className={`text-center p-2 cursor-pointer ${
                  date && isDateInRange(date) ? 'bg-blue-200' : ''
                } ${date && date.getTime() === startDate?.getTime() ? 'bg-blue-600 text-white' : ''}
                  ${date && date.getTime() === endDate?.getTime() ? 'bg-blue-600 text-white' : ''}`}
                onClick={() => date && handleDateClick(date)}
              >
                {date ? date.getDate() : ''}
              </div>
            ))}
          </div>
          <div className="mt-4">
            <p>От {formatDate(startDate)} до {formatDate(endDate)}</p>
          </div>
        </div>
      </div>

      <div className="mt-4 flex justify-end">
        <button
          onClick={() => onApply({startDate, endDate})}
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Применить
        </button>
      </div>
    </div>
  );
};

const DateRangePicker = ({ initialStartDate, initialEndDate, onApply}) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const applyHandler = ({startDate, endDate}) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setIsDatePickerOpen(false);
    if(onApply){
      onApply({startDate, endDate});
    }
  };


  const formatDateRange = () => {
    if (startDate && endDate) {
      const start = startDate.toLocaleDateString('ru-RU', { day: 'numeric', month: 'short' });
      const end = endDate.toLocaleDateString('ru-RU', { day: 'numeric', month: 'short' });
      return `${start} — ${end}`;
    }
    return 'Выберите дату';
  };

  return (
    <div className="p-4 bg-gray-100">
      <div className="flex flex-wrap items-center">
        <div className="relative mr-2 mb-2">
          <button
            onClick={() => {
              setIsDatePickerOpen(!isDatePickerOpen);
            }}
            className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          >
            {formatDateRange()}
            <ChevronDown className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </button>
          {isDatePickerOpen && (
            <div className="absolute z-10 mt-1 w-screen max-w-md bg-white rounded-md shadow-lg">
              <DateRangePickerUtil 
                initialStartDate={initialStartDate}
                initialEndDate={initialEndDate}
                onApply={applyHandler}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DateRangePicker;