import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components';
import { BASE_URL } from 'data/config';

function SubSectionForm({ subSection, onSubSectionChange }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onSubSectionChange(subSection.id, { ...subSection, [name]: value });
  };

  return (
    <div className="mb-4">
      <label className="block text-gray-700 text-sm font-bold mb-2">Sub-section Name</label>
      <input
        type="text"
        name="name"
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        value={subSection.name}
        onChange={handleChange}
        required
      />
      <label className="block text-gray-700 text-sm font-bold mb-2">Sub-section URL</label>
      <input
        type="text"
        name="url"
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        value={subSection.url}
        onChange={handleChange}
        required
      />
    </div>
  );
}

function SectionForm({ method = 'POST', section }) {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subSections, setSubSections] = useState(section?.sub_sections || []);
  const [sectionData, setSectionData] = useState({
    url: section?.url || '',
    name: section?.name || '',
  });

  const handleAddSubSection = () => {
    setSubSections([...subSections, { id: Date.now(), url: '', name: '' }]);
  };

  const handleSubSectionChange = (id, updatedSubSection) => {
    setSubSections(subSections.map(sub => (sub.id === id ? updatedSubSection : sub)));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('sub_sections', JSON.stringify(subSections));

    setIsSubmitting(true);
    try {
      const response = await fetch(`${BASE_URL}/dashboard/admin-api/sections`, {
        method,
        headers: {
          Authorization: 'Bearer token', 
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        alert('Failed to save section: ' + (errorData.message || 'Unknown error'));
        setIsSubmitting(false);
        return;
      }

      alert('Section saved successfully');
      navigate('/sections');
    } catch (error) {
      console.error('Fetch error:', error);
      alert('Network error or invalid response.');
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-xl px-8" encType="multipart/form-data">
      <div className="mb-4">
        <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">Section Name</label>
        <input
          id="name"
          type="text"
          name="name"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          value={sectionData.name}
          onChange={(e) => setSectionData({ ...sectionData, name: e.target.value })}
          required
        />
      </div>

      <div className="mb-4">
        <label htmlFor="url" className="block text-gray-700 text-sm font-bold mb-2">Section URL</label>
        <input
          id="url"
          type="text"
          name="url"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          value={sectionData.url}
          onChange={(e) => setSectionData({ ...sectionData, url: e.target.value })}
          required
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2">Sub-sections</label>
        {subSections.map((subSection, index) => (
          <SubSectionForm
            key={subSection.id}
            subSection={subSection}
            onSubSectionChange={handleSubSectionChange}
          />
        ))}
        <button
          type="button"
          onClick={handleAddSubSection}
          className="mt-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add Sub-section
        </button>
      </div>

      <div className="float-right">
        <button type="button" onClick={() => navigate('/sections')} disabled={isSubmitting} className='mr-3'>
          Cancel
        </button>
        <Button
          color="white"
          bgColor="blue"
          disabled={isSubmitting}
          text={isSubmitting ? 'Submitting...' : 'Save'}
          type="submit"
          borderRadius="10px"
          className="m-2"
        />
      </div>
    </form>
  );
}

export default SectionForm;
