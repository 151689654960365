import {
    json,
    defer, 

  } from 'react-router-dom';
import { BASE_URL } from '../../../data/config';



export async function loadBonusesList() {
  const token = "token";
  let url = BASE_URL + "/pos/admin-api/bonuses/";
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
  if (!response.ok) {
    throw json(
      { message: "Failed to load bonuses" },
      {
        status: response.status,
        statusText: response.statusText,
      }
    );
  } else {
    const resData = await response.json();
    return resData.response;
  }
}

async function loadBonusDetail(id) {
    const response = await fetch(BASE_URL + "/pos/admin-api/bonuses/?id=" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer token",
      },
    });
  
    if (!response.ok) {
      throw json(
        { message: "Could not fetch details for selected bonus." },
        {
          status: 500,
        }
      );
    } else {
      const resData = await response.json();
      return resData.response;
    }
  }


export async function bonusDetailLoader({ request, params }) {
  const id = params.id;

  return defer({
    bonus: await loadBonusDetail(id),
  });
}