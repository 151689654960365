import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Search, Check, ChevronDown, X } from 'lucide-react';
import { loadStoresList } from 'services';

const StoreSelector = ({ 
  onSelect, 
  onRemove,
  placeholder = "Select stores...",
  selectedStores = []
}) => {
  const [open, setOpen] = useState(false);
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const dropdownRef = useRef(null);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      loadStores();
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const loadStores = useCallback(async (searchString = "") => {
    setLoading(true);
    try {
      const queryParams = [
        searchString && `search=${searchString}`,
      ].filter(Boolean);
      
      const queryString = queryParams.length > 0 
        ? `?${queryParams.join('&')}` 
        : '';
      
      const response = await loadStoresList(queryString);
      setStores(response.rows);
    } catch (error) {
      console.error('Failed to load stores:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSearch = useCallback((value) => {
    setSearchValue(value);
    const debounceTimeout = setTimeout(() => {
      loadStores(value);
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [loadStores]);

  // const handleSelect = useCallback((store) => {
  //   setSelectedStores(prev => {
  //     const isSelected = prev.some(e => e.id === store.id);
  //     const newSelection = isSelected
  //       ? prev.filter(e => e.id !== store.id)
  //       : [...prev, store];
  //     onSelect?.(newSelection.map(e => ({ id: e.id, name: e.name })));
  //     return newSelection;
  //   });
  // }, [onSelect]);

  // const removeStore = useCallback((storeId) => {
  //   setSelectedStores(prev => {
  //     const newSelection = prev.filter(e => e.id !== storeId);
  //     onSelect?.(newSelection.map(e => ({ id: e.id, name: e.name })));
  //     return newSelection;
  //   });
  // }, [onSelect]);

  const getButtonText = () => {
    if (selectedStores.length === 0) return placeholder;
    if (selectedStores.length === 1) return selectedStores[0].name;
    return `Chosen ${selectedStores.length}`;
  };

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className="w-full px-4 py-2 text-left bg-white border rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
      >
        <div className="flex items-center justify-between">
          <span className={`block truncate ${selectedStores.length === 0 && 'text-gray-500'}`}>
            {getButtonText()}
          </span>
          <ChevronDown className="w-4 h-4 ml-2 text-gray-400" />
        </div>
      </button>

      {selectedStores.length > 0 && (
        <div className="flex flex-wrap gap-2 mt-2">
          {selectedStores.map((store) => (
            <div
              key={store.id}
              className="flex items-center gap-1 px-2 py-1 text-sm bg-blue-100 text-blue-800 rounded-full"
            >
              <span>{store.name}</span>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove(store.id);
                }}
                className="p-1 hover:bg-blue-200 rounded-full"
              >
                <X className="w-3 h-3" />
              </button>
            </div>
          ))}
        </div>
      )}

      {open && (
        <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg">
          <div className="flex items-center px-3 py-2 border-b">
            <Search className="w-4 h-4 text-gray-400" />
            <input
              type="text"
              className="w-full px-2 py-1 ml-2 text-sm focus:outline-none"
              placeholder="Search stores..."
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>

          <div className="max-h-60 overflow-auto">
            {loading ? (
              <div className="px-4 py-2 text-sm text-gray-500">Loading...</div>
            ) : stores.length === 0 ? (
              <div className="px-4 py-2 text-sm text-gray-500">No stores found</div>
            ) : (
              stores.map((store) => (
                <button
                  key={store.id}
                  className="w-full px-4 py-2 text-sm text-left hover:bg-gray-100 focus:outline-none focus:bg-gray-100 flex items-center justify-between"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!selectedStores.some(e => e.id === store.id)) {
                      onSelect?.(store);
                    }
                    else{
                      onRemove(store.id);
                    }
                  }}
                >
                  <span>{store.name}</span>
                  {selectedStores.some(e => e.id === store.id) && (
                    <Check className="w-4 h-4 text-blue-500" />
                  )}
                </button>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default StoreSelector;