
import React, { useState, useEffect } from 'react';
import { Check } from 'lucide-react';
import { BASE_URL } from 'data/config';


const ConsultantSales = ({ startDate, endDate, token, onChoose=()=>{} }) => {
    const [sales, setSales] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    

    const fetchConsultantDocs = async (page) => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          `${BASE_URL}/admin-api/consultant-docs?start_date=${startDate}&end_date=${endDate}&page=${page}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
        );
  
        if (!response.ok) {
          throw new Error('Failed to fetch consultant docs');
        }
  
        const data = await response.json();
        if (data.status === 0) {
          setSales((prevSales) => [...prevSales, ...data.response.docs]);
          setTotalPages(data.response.total_pages);
        } else {
          throw new Error(data.message || 'An error occurred');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      setCurrentPage(1);
      setSales([]);
      fetchConsultantDocs(1);
    }, [startDate, endDate]);
  
    const loadMoreDocs = () => {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      fetchConsultantDocs(nextPage);
    };
  
    return (
      <div className="bg-white rounded-lg shadow overflow-x-auto">
        {/* Error Display */}
        {error && <div className="text-red-500 mb-4">{error}</div>}
  
        <table className="w-full">
          <thead>
            <tr className="bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <th className="p-3">ККМ</th>
              <th className="p-3">Документ</th>
              <th className="p-3">Время</th>
              <th className="p-3">Позиций</th>
              <th className="p-3">Сумма</th>
              <th className="p-3">Оплаченные</th>
              <th className="p-3">Отправитель</th>
              <th className="p-3">Получатель</th>
              <th className="p-3">Сотрудник</th>
              <th className="p-3">Комментарий</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {sales.map((sale, index) => (
              <tr key={index} className="hover:bg-gray-50" >
                <td className="p-3">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 text-blue-600 rounded"
                      checked={sale.kkm}
                      readOnly
                    />
                  </div>
                </td>
                <td className="p-3">
                  <p className="text-blue-600 hover:underline flex items-center" onClick={() => onChoose(sale)}>
                    {sale.number}
                  </p>
                </td>
                <td className="p-3">{new Date(sale.created).toLocaleString()}</td>
                <td className="p-3">{sale.quantity}</td>
                <td className="p-3">{parseFloat(sale.sum).toFixed(2)}</td>
                <td className="p-3"> {parseFloat(sale.sum).toFixed(2)}</td>
                <td className="p-3">{sale.meta.from}</td>
                <td className="p-3">{sale.meta.to}</td>
                <td className="p-3">{sale.consultant.first_name || ''}</td>
                <td className="p-3">{sale.comment || ''}</td>
              </tr>
            ))}
          </tbody>
        </table>
  
        {/* Load More Button */}
        {currentPage < totalPages && (
          <div className="p-4 text-center">
            <button
              onClick={loadMoreDocs}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Load More'}
            </button>
          </div>
        )}
      </div>
    );
  };
  

export default ConsultantSales;
  