import React from 'react';
import { List, StoreItem } from 'components';


const formatDate = (date) => new Date(date).toLocaleDateString();

const StoresList = () => {
  const filterOptions = [
    {
      key: 'name',
      label: 'Name',
      field:  (props) => (
        <input 
          type="text"
          placeholder="Наименование"
          value={props.value}
          className={props.className}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      initialValue: '', 
    },
  ];

  const columns = [
    { key: 'id', label: 'ID', hide: true},
    { key: 'name', label: 'Имя' },
    { key: 'address', label: 'Адрес', component: (item) => <>{item.address && item.address.meta.name}</> },
    { key: 'created', label: 'Создан', component: (item) => <>{formatDate(item.created)}</> },
  ];


  return (
    <List
      targetUrl="/admin-api/stores"
      filterOptions={filterOptions}
      columns={columns}
      ItemComponent={StoreItem}
    />
  );
};

export default StoresList;
