import { BASE_URL } from "../data/config";

export async function loadList(endpoint, queryString = "", token = "token") {
  const url = BASE_URL + endpoint + queryString;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to load data from ${endpoint} with status ${response.status} error: ${response.statusText}`);
  }

  const data = await response.json();
  return data.response;
}

export async function loadItem(endpoint, id, token = 'token') {
  const url = BASE_URL + endpoint + id + "/";

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to load data from ${endpoint} with status ${response.status} error: ${response.statusText}`);
  }

  const data = await response.json();
  return data.response;
}

export async function exportList(endpoint, queryString = "", token = "token") {
  console.log("exportList", endpoint, queryString, token);
  const url = BASE_URL + endpoint + queryString;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to load data from ${endpoint} with status ${response.status} error: ${response.statusText}`);
  }

  const blob = await response.blob(); // Get the binary data as a Blob
  const downloadUrl = window.URL.createObjectURL(blob); // Create a download link
  const a = document.createElement("a");
  a.href = downloadUrl;
  a.download = "export.xlsx"; // Default file name
  document.body.appendChild(a);
  a.click(); // Trigger the download
  a.remove();
  window.URL.revokeObjectURL(downloadUrl);
}


export async function crudEmployee(data, token="token"){
  // check for id and if exists  update
  let url = BASE_URL + "/admin-api/employees/";
  if(data.id){
    url += `${data.id}/`;
  }
  const response = await fetch(url, {
    method: data.id ? "PUT" : "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`Failed to create employee with status ${response.status} error: ${response.statusText}`);
  }

  const responseData = await response.json();
  return responseData.response;
}



// ! Company
export async function loadEmployeesList(queryString = "", token = "token") {
  return loadList("/admin-api/employees", queryString, token);
}

export async function loadEmployeeGroupsList(queryString="", token="token") {
    return loadList("/admin-api/employee-groups", queryString, token);
}

export async function loadStoresList(queryString="", token = "token") {
    return loadList("/admin-api/stores", queryString, token);
}

// ! Parties
export async function loadClientsList(queryString="", token="token") {
    return loadList("/parties/admin-api/clients", queryString, token);
}

// ! CRM
export async function loadNotificationsList(queryString="", token="token") {
    return loadList("/crm/admin-api/notifications/list", queryString, token);
}

export async function loadNotificationTemplatesList(queryString="", token="token") {
    return loadList("/crm/admin-api/notification-templates", queryString, token);
}



// ! Docs
export async function loadDocsList(queryString="", token="token") {
    return loadList("/docs/admin-api/docs", queryString, token);
}

export async function loadPositionsList(queryString, token="token") {
    return loadList("/docs/admin-api/positions", queryString, token);
}


// ! Dashboard
export async function loadSectionsList(queryString, token="token") {
    return loadList("/dashboard/admin-api/sections", queryString, token);
}

