import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Navbar, ThemeSettings } from '../components/index';
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { FiSettings } from 'react-icons/fi';
import { useStateContext } from "../contexts/ContextProvider";
import { useRightModal } from '../contexts/RightModalContext';
import { RightModal } from '../components';
import AuthenticationPage from './Authentication';
import ErrorPage from './Error';
import { hasAccessToRoute } from './utils';

const RightModalContainer = () => {
  const { modals, closeRightModal } = useRightModal();

  return (
    <>
      {modals.map((modal, index) => (
        <RightModal 
          key={modal.id}
          title={modal.title || `Modal ${index + 1}`}
          afterClose={() => closeRightModal(modal.id)}
        >
          {modal.component}
        </RightModal>
      ))}
    </>
  );
};

const RootLayout = () => {
  const { currentMode, currentColor, themeSettings, setThemeSettings, token, user } = useStateContext();
  const location = useLocation();

  if (user && user.sections && !hasAccessToRoute(location.pathname, user.sections)) {
    return <ErrorPage />;
  }

  if(token === null) return <AuthenticationPage/>;

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <Navbar />

      <div className="flex flex-col relative dark:bg-main-dark-bg">
        <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
          <TooltipComponent content="Settings" position="Top">
            <button
              type="button"
              onClick={() => setThemeSettings(true)}
              className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
              style={{ background: currentColor, borderRadius: "50%" }}
            >
              <FiSettings />
            </button>
          </TooltipComponent>
        </div>

        <div className="bg-main-bg dark:bg-main-dark-bg w-full min-h-screen">
          <RightModalContainer />
          <div className="mt-32">
            {themeSettings && <ThemeSettings/>}
            <div className="pt-10 bg-white dark:bg-gray-700 rounded-3xl">
              <div className='mx-auto max-w-screen-xl bg-white dark:bg-gray-700'>
                  <Outlet />
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default RootLayout;