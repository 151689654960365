import React from 'react';

const Loading = () => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="flex items-center justify-center space-x-4">
        {[0, 1, 2, 3, 4].map((index) => (
          <div
            key={index}
            className="w-6 h-6 bg-blue-500 rounded-full"
            style={{
              animation: 'highBounce 1s infinite',
              animationDelay: `${index * 0.1}s`,
              transform: 'translateY(0)',
            }}
          />
        ))}
      </div>
      <style>{`
        @keyframes highBounce {
          0%, 100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-40px);
          }
        }
      `}</style>
    </div>
  );
};

export default Loading;