import React from 'react';
import { CreditCard } from 'lucide-react';

const Test1Page = () => {
  return (
    <div className="bg-gray-100">
      <header className="bg-white shadow-sm">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <div className="flex space-x-4">
            <span className="text-xl font-bold">детский мир</span>
            <span className="text-xl font-bold text-blue-600">зоозавр</span>
          </div>
          <nav className="hidden md:flex space-x-4">
            <a href="#" className="text-gray-600 hover:text-blue-600">Покупки по карте</a>
            <a href="#" className="text-gray-600 hover:text-blue-600">Статистика и баланс</a>
            <a href="#" className="text-gray-600 hover:text-blue-600">Информация о карте</a>
            <a href="#" className="text-gray-600 hover:text-blue-600">Вопросы и ответы</a>
          </nav>
        </div>
      </header>

      <main>
        <section className="bg-blue-50 py-12">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <div className="mb-8 md:mb-0">
                <h1 className="text-4xl font-bold mb-4">С бонусной картой выгоднее</h1>
                <p className="text-xl mb-6">Копите и списывайте бонусы в Детском мире и Зоозавре</p>
                <div className="space-x-4">
                  <button className="bg-blue-600 text-white px-6 py-2 rounded-full hover:bg-blue-700 transition duration-300">Получить карту</button>
                  <button className="border border-blue-600 text-blue-600 px-6 py-2 rounded-full hover:bg-blue-100 transition duration-300">Активировать карту</button>
                </div>
              </div>
              <div className="relative">
                <div className="w-48 h-48 bg-blue-200 rounded-full"></div>
                <div className="w-32 h-32 bg-purple-200 rounded-full absolute bottom-0 right-0"></div>
                <CreditCard className="text-blue-600 w-24 h-24 absolute top-8 right-8" />
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white py-12">
          <div className="container mx-auto px-4">
            <h2 className="text-2xl font-bold mb-4">Действует в Зоозавре и Детском мире</h2>
            <div className="flex flex-col md:flex-row justify-between items-center">
              <div>
                <p className="mb-2">В Детском мире за покупку базовый бонус до 2%</p>
                <p className="mb-2">и еще всегда начисляется 1%</p>
                <p>В Зоозавре — 2% на все покупки</p>
              </div>
              <div className="mt-8 md:mt-0 border border-gray-200 rounded-lg p-4 shadow-md">
                <img src="/api/placeholder/200/250" alt="Product" className="mb-4 rounded" />
                <p className="text-2xl font-bold">1999 ₽</p>
                <p className="text-sm text-gray-600">+100 бонусов</p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-blue-50 py-12">
          <div className="container mx-auto px-4">
            <h2 className="text-2xl font-bold mb-8 text-center">Что и как можно оплатить бонусами?</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                { icon: '💰', title: 'Оплачивайте любые товары из сетей' },
                { icon: '🛍️', title: 'Тратьте сразу или копите бонусы для больших покупок' },
                { icon: '📱', title: 'Пользуйтесь картой в приложении или на кассе' },
              ].map((item, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-md text-center">
                  <span className="text-4xl mb-4 block">{item.icon}</span>
                  <p className="text-gray-800">{item.title}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Test1Page;