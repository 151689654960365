import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-react-notifications/styles/material.css';

import 'leaflet/dist/leaflet.css';
import App from "./App";

import { ContextProvider } from "./contexts/ContextProvider";
import { RightModalProvider } from "contexts/RightModalContext";
// import { registerLicense } from '@syncfusion/ej2-react-licensing';

// registerLicense('Ngo9BigBOggjHTQxAR8/V1NAaF1cVWhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjWn9ccXRXRGVZU0J+Xg==');

ReactDOM.render(
  <ContextProvider>
    <RightModalProvider>
      <App />
    </RightModalProvider>
  </ContextProvider>,
  document.getElementById("root")
);
